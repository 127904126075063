import { ReportStatusTypeEnum } from '__generated__/UtleggsappenApi';
import { useAccount } from 'api/accounts/useAccount';
import { useAccounts } from 'api/accounts/useAccounts';
import { useDepartments } from 'api/departments/useDepartments';
import { useOrganizations } from 'api/organizations/useOrganizations';
import { useReports } from 'api/reports/useReports';
import BarChart from 'components/graph/BarChart';
import { LoadingIndicator } from 'components/layout/LoadingIndicator';
import Widget from 'components/layout/Widget';
import { Checklist, ChecklistItem } from 'components/lists/Checklist';
import { useStoredOrg } from 'context/OrganizationContext';
import { useStoredUser } from 'context/UserContext';
import { useTheme } from 'context/theme';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { cn } from 'utils/className';

export default function Home() {
  const date = new Date();
  const currentYear = date.getFullYear();
  const [data, setData] = useState<{ title: string; value: number }[]>([]);
  const { data: reports, isLoading: isLoadingReports } = useReports();
  const navigate = useNavigate();
  const { data: accounts, isLoading: isLoadingAccounts } = useAccounts();
  const { data: account } = useAccount();
  const { data: organizations, isLoading: isLoadingOrganizations } = useOrganizations();
  const { data: departments, isLoading: isLoadingDepartments } = useDepartments();
  const { organization } = useStoredOrg();
  const { user } = useStoredUser();
  const theme = useTheme();
  const combinedRole = Math.min(user.role, account?.role ?? 4);
  const [checklistCompleted, setChecklistCompleted] = useState(false);
  // Parse boolean from string from localstorage
  const [hideChecklist, setHideChecklist] = useState(
    localStorage.getItem(`hideChecklist-${organization?.handle || 'default'}`),
  );
  const [items, setItems] = useState<ChecklistItem[]>([]);

  useEffect(() => {
    function getMonthlyExpenses() {
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des'];
      const tempData: typeof data = [];
      for (const i in months) {
        tempData.push({
          title: months[i],
          value: 0,
        });
      }

      reports
        ?.filter((x) => parseInt(x.createdAt?.toString() ?? '0') === currentYear)
        .filter((x) => x.status !== 'DRAFT' && x.status !== 'DELETED')
        .map((x) => (tempData[parseInt(x.createdAt.toString().split('-')[1]) - 1].value += 1));
      setData(tempData);
    }
    getMonthlyExpenses();
  }, [currentYear, reports]);

  useEffect(() => {
    if (
      departments?.length! > 0 &&
      (organization.invoiceAsEhf || organization.invoiceEmail) &&
      organization.iconUrl &&
      (accounts?.length! > 1 || organization.subscription?.subscriptionTier.dashboard)
    ) {
      setChecklistCompleted(true);
    }
  }, [organization, reports, departments, accounts]);

  useEffect(() => {
    setItems([
      {
        active:
          organization.subscription?.subscriptionTier?.departmentsLimit !== undefined &&
          organization.subscription?.subscriptionTier.departmentsLimit! > 0,
        item: (
          <Checklist.Item
            emoji="🏢"
            title="Opprett en avdeling"
            subtitle="Lag din første avdeling"
            checked={departments?.length! > 0}
            buttonText="Opprett"
            onClick={() => navigate('avdelinger#ny')}
          />
        ),
      },
      {
        active: organization.subscription?.subscriptionTier.dashboard === true,
        item: (
          <Checklist.Item
            emoji="😀"
            title="Sett opp innsending for utleggsskjema"
            subtitle={
              organization.subscription?.subscriptionTier.ehf
                ? 'Velg om du skal sende utleggsskjema som EHF eller e-post til fakturamottak'
                : 'Velg hvilken e-postadresse fakturaer skal sendes til'
            }
            checked={organization?.invoiceAsEhf || organization.invoiceEmail ? true : false}
            buttonText="Gå til innstillinger"
            onClick={() => navigate('innstillinger/administrer')}
          />
        ),
      },
      {
        active: organization.subscription?.subscriptionTier.customLogo === true,
        item: (
          <Checklist.Item
            emoji="🎨"
            title="Last opp logo"
            checked={organization?.iconUrl !== undefined}
            subtitle="Gjør bedriften din gjenkjennbar"
            buttonText="Last opp"
            onClick={() => navigate('innstillinger/organisasjon')}
          />
        ),
      },
      {
        active: organization.subscription?.subscriptionTier.dashboard === true,
        item: (
          <Checklist.Item
            emoji="🙋‍♂️"
            title="Inviter brukere"
            checked={accounts?.length! > 1}
            subtitle="Kom i gang med Utleggsappen"
            buttonText="Inviter"
            onClick={() => navigate('brukere#ny')}
          />
        ),
      },
      {
        active: organization.subscription?.subscriptionTier.dashboard !== true,
        item: (
          <Checklist.Item
            emoji="💳"
            title="Aktiver abonnement"
            checked={organization.subscription?.subscriptionTier.dashboard === true}
            subtitle="Velg abonnement og aktiver bedriften din"
            buttonText="Velg abonnement"
            onClick={() => navigate('innstillinger/abonnement/endre')}
          />
        ),
      },
    ]);
  }, [departments, organization, accounts?.length]);

  // Om brukeren ikke er med i noen organisasjoner og ikke er superadmin
  if (organizations?.length === 0 && user.role > 1) {
    return <Navigate to="/" replace />;
  }

  if (!reports || isLoadingReports || isLoadingAccounts || isLoadingDepartments || isLoadingOrganizations) {
    return <LoadingIndicator size="lrg" />;
  }

  const greeting: string = date.getHours() < 12 ? 'God morgen' : date.getHours() < 18 ? 'God ettermiddag' : 'God kveld';

  const utleggChildren = (
    <>
      <h2 className="normalheading">Utlegg</h2>
      <p className="bodylarge">
        Venter på godkjenning: {reports.filter((report) => report.status === ReportStatusTypeEnum.PENDING).length || 0}
      </p>
      <p className="bodylarge">
        Avslått: {reports.filter((report) => report.status === ReportStatusTypeEnum.REJECTED).length || 0}
      </p>
    </>
  );

  return (
    <>
      <h1 className="bigheading py-5" style={{ color: theme.label.primary }}>
        {greeting},<br />
        {user.givenName}
      </h1>
      <Widget.Grid>
        {reports && reports.length > 0 && (checklistCompleted || hideChecklist || combinedRole >= 2) && (
          <Widget.Large>
            {/* Statistikk */}
            <h2 className="normalheading" style={{ color: theme.label.primary }}>
              Utlegg i {currentYear}
            </h2>
            <BarChart className="mt-8 px-8" data={data} />
          </Widget.Large>
        )}
        {!(checklistCompleted || hideChecklist || combinedRole >= 2) && (
          <Widget.Large className="overflow-hidden px-0">
            <div className="flex items-center justify-between px-6">
              <h2 className="normalheading" style={{ color: theme.label.primary }}>
                Kom i gang
              </h2>
            </div>
            <Checklist setHideChecklist={setHideChecklist} items={items} />
          </Widget.Large>
        )}
        {combinedRole <= 1 ? (
          // Admin
          <>
            {organization.subscription?.subscriptionTier.dashboard === true ? (
              <Widget.Large
                onClick={() => navigate('brukere')}
                className={cn(
                  'flex flex-col gap-y-2 hover:cursor-pointer hover:shadow-lg',
                  organization.subscription?.subscriptionTier.dashboard !== true && 'lg:col-start-3',
                )}>
                <h2 className="normalheading" style={{ color: theme.label.primary }}>
                  Brukere
                </h2>
                <p className="bodylarge">
                  Inviterte:{' '}
                  {organization.invitations?.filter((invitation) => invitation.email || invitation.phoneNumber).length}
                </p>
                <p className="bodylarge">Aktiverte: {accounts?.length}</p>
              </Widget.Large>
            ) : (
              <>
                {organization?.isApprovalFlowActive ||
                reports.filter((report) => report.status === ReportStatusTypeEnum.PENDING).length > 0 ? (
                  <>
                    <Widget.Medium
                      onClick={() => navigate('utlegg')}
                      className="flex flex-col gap-y-2 hover:cursor-pointer hover:shadow-lg">
                      {utleggChildren}
                    </Widget.Medium>
                    <Widget.Medium
                      onClick={() => navigate('brukere')}
                      className={cn(
                        'flex flex-col gap-y-2 hover:cursor-pointer hover:shadow-lg',
                        organization.subscription?.subscriptionTier.dashboard &&
                          organization.subscription?.subscriptionTier.dashboard !== true &&
                          'lg:col-start-3',
                      )}>
                      {/* Brukere */}
                      <h2 className="normalheading" style={{ color: theme.label.primary }}>
                        Brukere
                      </h2>
                      <p className="bodylarge">
                        Inviterte:{' '}
                        {
                          organization.invitations?.filter((invitation) => invitation.email || invitation.phoneNumber)
                            .length
                        }
                      </p>
                      <p className="bodylarge">Aktiverte: {accounts?.length}</p>
                    </Widget.Medium>
                  </>
                ) : (
                  <Widget.Large
                    onClick={() => navigate('brukere')}
                    className={cn('flex flex-col gap-y-2 hover:cursor-pointer hover:shadow-lg')}>
                    {/* Brukere */}
                    <h2 className="normalheading" style={{ color: theme.label.primary }}>
                      Brukere
                    </h2>
                    <p className="bodylarge">
                      Inviterte:{' '}
                      {
                        organization.invitations?.filter((invitation) => invitation.email || invitation.phoneNumber)
                          .length
                      }
                    </p>
                    <p className="bodylarge">Aktiverte: {accounts?.length}</p>
                  </Widget.Large>
                )}
              </>
            )}
          </>
        ) : (
          // Bruker
          (organization?.isApprovalFlowActive ||
            reports.filter((report) => report.status === ReportStatusTypeEnum.PENDING).length > 0) && (
            <Widget.Large
              onClick={() => navigate('utlegg')}
              className="flex flex-col gap-y-2 hover:cursor-pointer hover:shadow-lg">
              {utleggChildren}
            </Widget.Large>
          )
        )}
      </Widget.Grid>
    </>
  );
}
