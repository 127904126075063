import { Account } from '__generated__/UtleggsappenApi';
import { useAccount } from 'api/accounts/useAccount';
import { useAccounts } from 'api/accounts/useAccounts';
import { useDepartments } from 'api/departments/useDepartments';
import { Line } from 'assets/svgcomponents/Line';
import { alert } from 'components/alerts/Alert';
import BackButton from 'components/buttons/BackButton';
import { LargeButton } from 'components/buttons/LargeButton';
import Input from 'components/input-field/Input';
import { toast } from 'components/toast/Toast';
import { useStoredOrg } from 'context/OrganizationContext';
import { useStoredUser } from 'context/UserContext';
import { useTheme } from 'context/theme';
import { Form, Formik, FormikErrors } from 'formik';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { accountService } from 'services/accountService';
import { departmentService } from 'services/departmentService';
import { formatPhoneNumber } from 'utils/format';

export function EditUser() {
  const [account, setAccount] = useState<Account | null>(null);
  const [accountDepartments, setAccountDepartments] = useState<Account['departments'] | null>(null);
  const { hash } = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const { setUser } = useStoredUser();
  const { organization } = useStoredOrg();
  const { data: departments } = useDepartments();
  const { data: accounts } = useAccounts();
  const { data: userAccount } = useAccount();

  const accountId = hash.replace('#', '');

  const [disableDelete, setDisableDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (account && account.role <= 1 && accounts && accounts?.filter((a) => a.role <= 1).length <= 1) {
      setDisableDelete(true);
    }
  }, [accounts, account]);

  useEffect(() => {
    async function fetchUser() {
      const res = await accountService.getById(accountId);
      setAccount(res);
      setAccountDepartments(res.departments);
    }
    if (accountId) {
      fetchUser();
    }
  }, [accountId]);

  const accountUser = account?.user;

  async function handleDelete() {
    setIsDeleting(true);
    await accountService
      .delete(accountId)
      .then(() => {
        toast.success({ description: 'Brukeren ble slettet' });
        navigate('..');
      })
      .catch(() => toast.error({ description: 'Det oppsto en feil' }))
      .finally(() => {
        setIsDeleting(false);
      });
  }

  const handleToggleAction = async ({ role }: { role: number }) => {
    await accountService
      .update(accountId, { role })
      .catch(() => toast.error({ description: 'Kunne ikke oppdatere bruker' }));
    if (role === 4) {
      accountDepartments?.forEach((department) => {
        departmentService.disconnectAccounts(department.id, [accountId]);
      });
      setAccountDepartments([]);
    }
    setUser();

    if (userAccount?.id === account?.id) {
      if (role === 4) navigate('/');
      else if (role === 2) navigate(`/${organization?.handle}`);
    }
  };

  const showPermissionsModal = (
    pendingRole: number | null,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => Promise<void | FormikErrors<{
      role: number;
    }>>,
  ) => {
    alert.info({
      title: 'Endre tilgang',
      description:
        'Dette vil endre tilgangen til din egen bruker, og kan føre til at du mister tilgang til deler av systemet. Ønsker du å fortsette?',
      onAction: () => {
        setFieldValue('role', pendingRole ?? 4);
        handleToggleAction({ role: pendingRole ?? 4 });
      },
    });
  };

  if (!account) {
    return (
      <div className="flex flex-grow flex-col space-y-8">
        <div className="border-b pb-8"></div>
      </div>
    );
  }

  return (
    <div className="flex w-[400px] flex-col justify-between gap-y-5 pt-5" style={{ color: theme.label.primary }}>
      <BackButton />
      <h1 className="bigheading">Rediger bruker</h1>
      <div className="flex flex-col gap-y-4">
        <div className="flex flex-col gap-y-1">
          {accountUser && (
            <h2 className="smalltextbold">
              {accountUser.givenName} {accountUser.familyName}
            </h2>
          )}
          <p className="smalltext">{formatPhoneNumber(accountUser?.phoneNumber ?? '')}</p>
          {accountUser && <p className="smalltext">{accountUser.email}</p>}
        </div>
        {accountUser && (
          <div className="flex flex-col gap-y-1">
            <p className="smalltext">{accountUser.streetAddress}</p>
            <p className="smalltext">
              {accountUser.postalCode} {accountUser.region}
            </p>
          </div>
        )}
        {organization.subscription?.subscriptionTier.dashboard && (
          <Formik
            initialValues={{
              role: account.role,
            }}
            onSubmit={handleToggleAction}>
            {({ values, setFieldValue }) => {
              return (
                <Form className="flex flex-col gap-y-4">
                  <Line stroke={theme.background.accentemphasis} />
                  <Input.Toggle
                    disabled={disableDelete}
                    label="Dashbord"
                    description="Tilgang til dashbord og utvalgte avdelinger"
                    onChange={(webUser) => {
                      const role = webUser ? 2 : 4;
                      if (userAccount?.id === account.id) {
                        showPermissionsModal(role, setFieldValue);
                      } else if (role === 4 && account?.departments?.length) {
                        alert.error({
                          title: 'Fjern tilgang',
                          description:
                            'Denne brukeren er godkjenner for en eller flere avdelinger, og vil miste tilgang til disse. Ønsker du å fortsette?',
                          onAction: () => {
                            setFieldValue('role', role);
                            handleToggleAction({ role: role });
                          },
                        });
                      } else {
                        setFieldValue('role', role);
                        handleToggleAction({ role: role });
                      }
                    }}
                    value={disableDelete ? true : values.role <= 2}
                  />
                  <Input.Toggle
                    disabled={disableDelete}
                    label="Administrator"
                    description="Administratorer kan redigere brukere og avdelinger"
                    onChange={(adminUser) => {
                      const role = adminUser ? 1 : 2;
                      if (userAccount?.id === account.id) {
                        showPermissionsModal(role, setFieldValue);
                      } else {
                        setFieldValue('role', role);
                        handleToggleAction({ role: role });
                      }
                    }}
                    value={disableDelete ? true : values.role <= 1}
                  />
                  <motion.div
                    className="-m-1 flex flex-col gap-y-4 overflow-hidden p-1"
                    animate={{
                      height: values.role !== 2 ? 0 : 'fit-content',
                    }}
                    initial={{ height: 0 }}
                    transition={{
                      duration: 0.15,
                      curve: 'easeInOut',
                    }}>
                    <Line stroke={theme.background.accentemphasis} />
                    <Input.DropdownCheckbox
                      label="Avdelinger"
                      options={departments ?? []}
                      selectedOptions={accountDepartments ?? []}
                      onRemoveOption={(option) => {
                        setAccountDepartments((prev) => prev?.filter((el) => el.id !== option.id) ?? []);
                        departmentService.disconnectAccounts(option.id, [account.id]);
                      }}
                      onSelectOption={(option) => {
                        departmentService.connectAccounts(option.id, [account.id]);
                        setAccountDepartments(
                          (prev: any) =>
                            prev?.concat([{ id: option.id, title: option.title }]) ?? [
                              { id: option.id, title: option.title },
                            ],
                        );
                      }}
                      disabled={values.role > 2}
                    />
                  </motion.div>
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
      <div className="flex w-fit flex-row gap-x-4 pt-8">
        <LargeButton
          className="whitespace-nowrap"
          backgroundColor="light"
          defaultStyle="critical"
          onClick={() =>
            alert.error({
              title: 'Fjern bruker',
              description: 'Er du sikker på at du ønsker du å fjerne brukeren?',
              onAction: handleDelete,
              loading: isDeleting,
            })
          }
          disabled={disableDelete}>
          Fjern bruker
        </LargeButton>
        {disableDelete && (
          <p className="caption">Dette er den eneste brukeren med administratorrettigheter og kan ikke slettes.</p>
        )}
      </div>
    </div>
  );
}
