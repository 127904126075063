import { fetcher } from 'api';
import { useOrganization } from 'api/organizations/useOrganization';
import useSWR from 'swr';

export function useAccounts() {
  // ! Av en eller annen grunn er denne typen generert som 'Never', så jeg har kopiert typen direkte fra backend
  // Awaited<ReturnType<typeof Api.prototype.categories.findAll>>['data']
  const { data: organization } = useOrganization();

  const { data, error, mutate, isLoading } = useSWR<
    {
      id: string;
      role: number;
      activatedAt: Date;
      isStaff: boolean;
      deletedAt: Date;
      user: {
        id: string;
        phoneNumber: string;
        email?: string;
        givenName?: string;
        familyName?: string;
      };
      departments: {
        id: string;
        title: string;
      }[];
    }[]
  >(organization ? '/accounts/?' + new URLSearchParams({ organizationId: organization.id }) : null, fetcher, {
    shouldRetryOnError: false,
  });

  const nonDeletedAccounts = data?.filter((account) => !account.deletedAt);

  return {
    data: nonDeletedAccounts,
    isLoading,
    error,
    mutate,
  };
}
