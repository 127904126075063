import * as Tabs from '@radix-ui/react-tabs';
import { useRef, useState } from 'react';
import { useTheme } from 'context/theme';
import { ArrowButton } from 'components/buttons/ArrowButton';
import { APIProvider, Map } from '@vis.gl/react-google-maps';
import { Directions } from '../../../../components/maps/Directions';
import { expenseService } from 'services/expenseService';
import { useAttatchments } from 'api/expenses/useAttatchments';
import { vechicleTypes } from 'utils/staticTypes';
import { toast } from 'components/toast/Toast';
import { LoadingIndicator } from 'components/layout/LoadingIndicator';
import { cn } from 'utils/className';
import { Diet, Expense as ExpenseType } from '__generated__/UtleggsappenApi';
import { formatAmount } from 'utils/format';
import { useReport } from 'api/reports/useReport';
import { ChatTeardropDots } from 'assets/svgcomponents/ChatTeardropDots';
import { alert } from 'components/alerts/Alert';
import { Form, Formik } from 'formik';
import Input from 'components/input-field/Input';
import * as Yup from 'yup';
import { useDepartments } from 'api/departments/useDepartments';
import { SmallButton } from 'components/buttons/SmallButton';
import { List } from 'components/lists/List';
import { ListSmall } from 'components/lists/ListSmall';

export function Expense({
  data,
  id,
  revalidate,
  isOverviewTab,
}: {
  data: ExpenseType;
  id: string;
  revalidate: any;
  isOverviewTab: boolean;
}) {
  const theme = useTheme();
  const [activeAttatchmentIndex, setActiveAttatchmentIndex] = useState(0);
  const [isSettingRejectMessage, setIsSettingRejectMessage] = useState(false);
  const { data: attatchments } = useAttatchments(data.id, data.attachments!.length > 0);
  const { data: report, isLoading: isLoadingReport } = useReport(data.reportId);
  const { data: departments } = useDepartments();
  const submitFormRef = useRef<() => Promise<void>>();

  const reportDepartmentTitle =
    departments?.find((department) => department.id === report?.departmentId)?.title || 'Ikke oppgitt';

  function handleRejectMessage(message: string) {
    setIsSettingRejectMessage(true);
    expenseService
      .addRejectMessage(message, data.id)
      .then(async () => {
        await revalidate();
        toast.success({ description: 'Kommentar ble lagt til' });
        setIsSettingRejectMessage(false);
      })
      .catch(() => {
        toast.error({ description: 'Det oppsto en feil' });
      });
  }

  const dietsTotal = data.diets ? data.diets.length : 0;

  if (isLoadingReport) return <LoadingIndicator size="lrg" />;

  return (
    <Tabs.Content
      className="h-full max-h-full flex-1 overflow-hidden"
      value={id}
      style={{ backgroundColor: theme.background.primary }}>
      <div className="flex h-full">
        <div className="flex h-full w-1/2 overflow-y-auto px-6 pt-6">
          <List>
            {isOverviewTab && (
              <List.Item
                title="Dato for innsendelse"
                value={new Date(data.createdAt!).toLocaleDateString('no-NO', {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                })}
              />
            )}
            {(data.type === 'DEFAULT' || data.type === 'DRIVE') && (
              <List.Item
                title="Dato for utlegg"
                value={new Date(data.date!).toLocaleDateString('no-NO', {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                })}
              />
            )}
            {data.type === 'DEFAULT' && (
              <List.Item title="Betalingsmetode" value={data.paymentMethod === 'CARD' ? 'Kort' : 'Faktura'} />
            )}
            {data.type === 'DRIVE' && (
              <>
                <li className="flex flex-col py-3">
                  <p className="captionbold">Strekning</p>
                  <div className="flex items-center justify-between">
                    <p className="smalltext">{`${data.drive?.from} - ${data.drive?.to}`}</p>
                    <div
                      className="smalltext w-fit rounded-full border px-3 py-1"
                      style={{ borderColor: theme.border.border }}>
                      {data.drive?.returnTrip ? 'Tur/retur' : 'Èn vei'}
                    </div>
                  </div>
                </li>
                <List.Item
                  title="Bompenger"
                  value={data.drive?.toll ? `${formatAmount(data.drive?.toll)} kr` : 'Ikke oppgitt'}
                />
                <List.Item
                  title="Type kjøretøy"
                  value={vechicleTypes.find((type) => type.id === data.drive?.vehicle)?.title || 'Ikke oppgitt'}
                />
              </>
            )}
            {data.type === 'DRIVE' ? (
              <List.Item title="Avdeling" value={reportDepartmentTitle} />
            ) : (
              <>
                {data.department?.title && (
                  <List.Item title="Avdeling" value={data.department?.title || 'Ikke oppgitt'} />
                )}
              </>
            )}
            {data.project?.title && <List.Item title="Prosjekt" value={data.project?.title} />}
            {data.category?.title && <List.Item title="Utleggstype" value={data.category?.title || 'Ikke oppgitt'} />}
            {data.description && (
              <List.Item
                title={data.type === 'DRIVE' ? 'Formål' : 'Beskrivelse'}
                value={data.description || 'Ikke oppgitt'}
              />
            )}
            <List.Item title="Sum" value={`${formatAmount(data.amount)} kr`} />
            <div className="flex items-center space-x-3">
              <SmallButton
                defaultStyle="critical"
                backgroundColor="light"
                icon={<ChatTeardropDots className="w-6" />}
                iconPlacement="left"
                onClick={() =>
                  alert.info({
                    title: 'Legg til feil på utlegget',
                    actionLabel: 'Legg til',
                    onAction: () => {
                      if (submitFormRef.current) submitFormRef.current();
                    },
                    disableDefaultAction: true,
                    loading: isSettingRejectMessage,
                    children: (
                      <Formik
                        onSubmit={(formValues) => {
                          handleRejectMessage(formValues?.message);
                          alert.close();
                        }}
                        validateOnChange={false}
                        validateOnBlur={false}
                        initialValues={{ message: '' }}
                        validationSchema={Yup.object({ message: Yup.string().required('Påkrevd') })}>
                        {({ values, handleSubmit, handleBlur, errors, handleChange, submitForm }) => {
                          submitFormRef.current = submitForm;
                          return (
                            <Form onSubmit={handleSubmit} className="flex w-full flex-col items-center">
                              <Input.TextArea
                                value={values.message || ''}
                                onChange={handleChange}
                                error={errors.message as any}
                                onBlur={handleBlur}
                                name="message"
                                focusOnMount={values.message === ''}
                              />
                            </Form>
                          );
                        }}
                      </Formik>
                    ),
                  })
                }
              />
              <div className="relative">
                <div className={cn(isSettingRejectMessage && 'opacity-0')}>
                  <List.Item
                    title={data.rejectReason ? 'Kommentar fra behandler' : 'Legg til feil på utlegget'}
                    value={data.rejectReason || 'Denne meldingen blir sendt til brukeren'}
                  />
                </div>
                {isSettingRejectMessage && (
                  <div className="absolute inset-0 flex w-16 items-start">
                    <LoadingIndicator size="sml" />
                  </div>
                )}
              </div>
            </div>
          </List>
        </div>
        {data.attachments!.length > 0 && attatchments && attatchments.length > 0 && (
          <div className="flex h-full w-1/2 flex-1 grow flex-col items-center justify-center space-y-3 p-6">
            <div className="flex items-center space-x-8">
              <ArrowButton onClick={() => setActiveAttatchmentIndex(Math.max(0, activeAttatchmentIndex - 1))} />
              <p className="largetext">{`Vedlegg ${activeAttatchmentIndex + 1}/${data.attachments!.length}`}</p>
              <ArrowButton
                orientation="right"
                onClick={() =>
                  setActiveAttatchmentIndex(Math.min(data.attachments!.length - 1, activeAttatchmentIndex + 1))
                }
              />
            </div>
            <div
              className="w-full flex-1 rounded-medium p-3"
              style={{ backgroundColor: theme.background.accentstrong }}>
              <iframe src={attatchments[activeAttatchmentIndex].url} className="h-full w-full" title="attatchment" />
            </div>
          </div>
        )}
        {data.type === 'DRIVE' && (
          <div className="h-1/2 w-1/2 justify-center p-6">
            <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}>
              <Map disableDefaultUI={true} className="h-full w-full rounded-t-large">
                <Directions origin={data.drive?.from!} destination={data.drive?.to!} />
              </Map>
            </APIProvider>
            <div
              className="divide-x-white flex w-full divide-x rounded-b-large"
              style={{ backgroundColor: theme.background.accent }}>
              <div className="w-1/2 p-6">
                <p className="smalltextbold">Total avstand</p>
                <p className="smalltext">{`${formatAmount(data.drive?.distance as unknown as number)} km`}</p>
              </div>
              <div className="w-1/2 p-6">
                <p className="smalltextbold">Bompenger</p>
                <p className="smalltext">
                  {data.drive?.toll ? `${formatAmount(data.drive?.toll)} kr` : 'Ikke oppgitt'}
                </p>
              </div>
            </div>
          </div>
        )}
        {data.type === 'DIET' && data.diets?.length! > 0 && (
          <div className="h-full flex-1 overflow-y-auto p-6">
            <ListSmall title={`Diett for ${dietsTotal} dag${dietsTotal > 1 ? 'er' : ''}`}>
              {data.diets?.map((diet: Diet, index) => {
                return (
                  <div>
                    <ListSmall.Item
                      key={data.id + index}
                      title={new Date(diet.date).toLocaleDateString('no-NO', {
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric',
                      })}
                      value={
                        diet.breakfast && diet.lunch && diet.dinner
                          ? 'Alle måltider inkludert'
                          : [diet.breakfast && 'Frokost', diet.lunch && 'Lunsj', diet.dinner && 'Middag']
                              .filter((a) => a)
                              .join(' · ')
                      }
                      badge={diet?.amount + ' kr'}
                    />
                  </div>
                );
              })}
            </ListSmall>
          </div>
        )}
      </div>
    </Tabs.Content>
  );
}
