import * as ScrollArea from '@radix-ui/react-scroll-area';
import { Organization } from '__generated__/UtleggsappenApi';
import { useAccount } from 'api/accounts/useAccount';
import { useOrganization } from 'api/organizations/useOrganization';
import { useOrganizations } from 'api/organizations/useOrganizations';
import { useReports } from 'api/reports/useReports';
import { Approved } from 'assets/svgcomponents/Approved';
import { Archive } from 'assets/svgcomponents/Archive';
import { Company } from 'assets/svgcomponents/Company';
import { Settings } from 'assets/svgcomponents/Settings';
import { SmileyWink } from 'assets/svgcomponents/SmileyWink';
import { Chip } from 'components/chips/Chip';
import { colors } from 'constants/constants';
import { useStoredUser } from 'context/UserContext';
import { useTheme } from 'context/theme';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import useMeasure from 'react-use-measure';
import { cn } from 'utils/className';
import { Departments } from '../../assets/svgcomponents/Departments';
import { Home } from '../../assets/svgcomponents/Home';
import { Users } from '../../assets/svgcomponents/Users';

function OrgComponent({
  organization,
  disabled,
  closeOrganizationPicker,
}: Readonly<{
  organization: Organization;
  disabled: boolean;
  closeOrganizationPicker: () => void;
}>) {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <button
      className={cn('clickable smalltext flex w-fit flex-row items-center gap-x-1 rounded-small border-[1px] p-1')}
      style={{
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        color: theme.label.onaccent,
        fill: theme.label.onaccent,
        fillOpacity: '0',
      }}
      onClick={() => {
        navigate(`/${organization.handle}`);
        closeOrganizationPicker();
      }}
      disabled={disabled}>
      <div className="min-h-[29px] min-w-[29px]" />
      <h2 className="px-2.5 py-1 text-start">{organization.displayName}</h2>
    </button>
  );
}

export default function Navbar() {
  const theme = useTheme();

  const { user } = useStoredUser();
  const { data: organization } = useOrganization();
  const { data: organizations } = useOrganizations();
  const { data: account } = useAccount();
  const [organizationPickerIsOpen, setOrganizationPickerIsOpen] = useState(false);
  const [ref, { width }] = useMeasure();

  const combinedRole = Math.min(user.role, account?.role ?? 4);
  const otherOrgs = organizations?.filter((x) => x.id !== organization?.id);

  const { data: reports } = useReports();

  const [reportsCount, setReportsCount] = useState(0);

  useEffect(() => {
    if (Array.isArray(reports) && reports.length) {
      setReportsCount(reports?.filter((report) => report.status === 'PENDING').length);
    } else {
      setReportsCount(0);
    }
  }, [reports]);

  const navbarItems = [
    {
      type: 'item',
      id: 'home',
      visible: true,
      content: {
        title: 'Hjem',
        route: `${organization?.handle ?? ''}`,
        icon: <Home />,
      },
    },
    {
      type: 'item',
      id: 'approval',
      visible:
        combinedRole <= 2 &&
        organization?.subscription?.subscriptionTier.approvalFlow &&
        (organization?.isApprovalFlowActive || reportsCount > 0),
      content: {
        title: 'Godkjenning',
        route: `${organization?.handle}/utlegg`,
        icon: <Approved />,
        badge: reportsCount > 0 && reportsCount.toString(),
      },
    },
    {
      type: 'item',
      id: 'archive',
      visible: combinedRole <= 2 && organization?.subscription?.subscriptionTier.archive && organization,
      content: {
        title: 'Arkiv',
        route: `${organization?.handle}/arkiv`,
        icon: <Archive />,
      },
    },
    {
      type: 'divider',
      id: 'divider_1',
      visible: combinedRole <= 1 && organization?.subscription?.subscriptionTier.dashboard && organization,
    },
    {
      type: 'item',
      id: 'users',
      visible: combinedRole <= 1 && organization,
      content: {
        title: 'Brukere',
        route: `${organization?.handle}/brukere`,
        icon: <Users />,
      },
    },
    {
      type: 'item',
      id: 'departments',
      visible:
        combinedRole <= 1 &&
        organization?.subscription?.subscriptionTier.departmentsLimit &&
        organization?.subscription?.subscriptionTier.departmentsLimit > 0 &&
        organization,
      content: {
        title: 'Avdelinger',
        route: `${organization?.handle}/avdelinger`,
        icon: <Departments />,
      },
    },
    {
      type: 'divider',
      id: 'divider_2',
      visible: combinedRole <= 1 && organization?.subscription?.subscriptionTier.dashboard && organization,
    },
    {
      type: 'item',
      id: 'settings',
      visible: combinedRole <= 1 && organization,
      content: {
        title: 'Innstillinger',
        route: `${organization?.handle}/innstillinger`,
        icon: <Settings />,
      },
    },
  ] as const;

  return (
    <div
      className="flex"
      id="navbar"
      style={{
        minWidth: width,
      }}>
      <div
        className={'fixed flex h-screen w-fit flex-col justify-between px-10 py-6'}
        style={{
          backgroundColor: theme.base.accent,
        }}
        ref={ref}>
        <div className="flex flex-col">
          <div className="flex items-center py-2.5">
            <NavLink to={organization?.handle ?? ''}>
              <h1 className="normalheading" style={{ color: theme.label.onaccent }}>
                Utleggsappen
              </h1>
            </NavLink>
          </div>
          <div className="mt-[44px] flex flex-grow flex-col gap-y-[13px]">
            <div className="flex flex-col gap-y-[13px]">
              {navbarItems
                .filter((item) => item.visible)
                .map((item, index) => {
                  if (item.type === 'divider') {
                    return (
                      <div
                        key={item.id}
                        className="my-[13px] h-px w-full opacity-50 last:hidden"
                        style={{ backgroundColor: theme.background.secondary }}
                      />
                    );
                  } else if (item.type === 'item') {
                    return (
                      <div key={item.id} className="flex items-center space-x-2">
                        <NavLink
                          end={index === 0}
                          to={`${item.content.route}`}
                          className="clickable smalltext flex w-fit flex-row items-center gap-x-1 rounded-small border-[1px] p-1 transition-all"
                          style={({ isActive }) => {
                            const styles: React.CSSProperties = {};
                            if (isActive) {
                              styles.backgroundColor = theme.background.primary;
                              styles.borderColor = theme.label.secondary;
                              styles.color = theme.label.primary;
                              styles.fill = theme.label.primary;
                              styles.fillOpacity = '1';
                            } else {
                              styles.backgroundColor = 'transparent';
                              styles.borderColor = 'transparent';
                              styles.color = colors.whitealpha[700];
                              styles.stroke = colors.whitealpha[700];
                              styles.fill = colors.whitealpha[700];
                              styles.fillOpacity = '0';
                            }
                            return styles;
                          }}>
                          {item.content.icon}
                          <h2 className="px-2.5 py-1">{item.content.title}</h2>
                        </NavLink>
                        {item.id === 'approval' && item.content.badge && (
                          <Chip type="warning" value={item.content.badge} />
                        )}
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-y-4">
          {organization && (
            <div className="flex max-w-56 flex-col overflow-hidden">
              <motion.div
                animate={{
                  height: organizationPickerIsOpen ? 'auto' : '0px',
                  maxHeight: '120px',
                }}
                transition={{
                  duration: 0.2,
                }}
                initial={{ height: '0px' }}>
                <ScrollArea.Root className={cn('max-h-[120px]')} tabIndex={organizationPickerIsOpen ? 0 : -1}>
                  <ScrollArea.Viewport className="max-h-[120px] w-full">
                    {otherOrgs?.map((org) => (
                      <OrgComponent
                        key={org!.id}
                        organization={org!}
                        closeOrganizationPicker={() => setOrganizationPickerIsOpen(false)}
                        disabled={!organizationPickerIsOpen}
                      />
                    ))}
                  </ScrollArea.Viewport>
                  <ScrollArea.Scrollbar
                    className="mt-0.5 flex w-1 touch-none select-none transition-colors ease-in-out"
                    orientation="vertical">
                    <ScrollArea.Thumb
                      className="relative flex-1 rounded-full before:absolute before:left-1/2 before:top-1/2 before:h-full before:min-h-[44px] before:w-full before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-['']"
                      style={{ backgroundColor: theme.label.tertiary }}
                    />
                  </ScrollArea.Scrollbar>
                </ScrollArea.Root>
              </motion.div>
              <div className="z-10" style={{ backgroundColor: theme.base.accent }}>
                <button
                  className={cn(
                    'smalltext flex w-fit flex-row items-center gap-x-1 rounded-small border-[1px] p-1',
                    otherOrgs?.length !== 0 && 'clickable',
                  )}
                  style={{
                    backgroundColor: organizationPickerIsOpen ? theme.background.primary : 'transparent',
                    borderColor: organizationPickerIsOpen ? theme.label.secondary : 'transparent',
                    color: organizationPickerIsOpen ? theme.label.primary : theme.label.onaccent,
                    fill: organizationPickerIsOpen ? theme.label.primary : theme.label.onaccent,
                    fillOpacity: organizationPickerIsOpen ? '1' : '0',
                  }}
                  onClick={() => setOrganizationPickerIsOpen(!organizationPickerIsOpen)}
                  disabled={otherOrgs?.length === 0}>
                  <Company />
                  <h2 className="px-2.5 py-1 text-start">{organization.displayName}</h2>
                </button>
              </div>
            </div>
          )}
          <NavLink
            end={false}
            to={organization ? `${organization?.handle}/profil` : '/profil'}
            className={({ isActive }) =>
              cn(
                'clickable flex w-fit flex-row items-center gap-x-1 rounded-small border-[1px] p-1 transition-all',
                isActive ? 'smalltextbold' : 'smalltext',
              )
            }
            style={({ isActive }) => {
              const styles: React.CSSProperties = {};
              if (isActive) {
                styles.backgroundColor = theme.background.primary;
                styles.borderColor = theme.label.secondary;
                styles.color = theme.label.primary;
                styles.fill = theme.label.primary;
                styles.fillOpacity = '1';
              } else {
                styles.borderColor = 'transparent';
                styles.color = theme.label.onaccent;
                styles.stroke = theme.label.primary;
                styles.fill = theme.label.primary;
                styles.fillOpacity = '0';
              }
              return styles;
            }}>
            <SmileyWink className="rounded-full" styles={{ backgroundColor: theme.background.primary }} />
            {/* <h2 className="px-2.5 py-1 max-w-44">{user.givenName} {user?.familyName}</h2> */}
            <h2 className="max-w-44 px-2.5 py-1">Profil</h2>
          </NavLink>
        </div>
      </div>
    </div>
  );
}
