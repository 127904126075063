import { useMemo, useState } from 'react';
import { useTheme } from 'context/theme';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import Table from 'components/table/Table';
import SearchBar from 'components/input-field/SearchBar';
import { useReports } from 'api/reports/useReports';
import { DefaultCell, HeaderCell } from 'components/table/TableComponents';
import { FullscreenLoading } from 'components/layout/LoadingIndicator';
import { formatAmount } from 'utils/format';

const addBreakpoints = (value: string) => {
  if (value === 'Utleggsgruppe') {
    return 'Utleggs\u{00AD}gruppe';
  } else if (value === 'Kjøregodtgjørelse') {
    return 'Kjøregodt\u{00AD}gjørelse';
  } else if (value === 'Reiseregning') {
    return 'Reise\u{00AD}regning';
  } else {
    return value;
  }
};

export default function ListExpenses() {
  const { data: reports, isLoading } = useReports();
  const [searchFilter, setSearchFilter] = useState('');

  const filteredReports = useMemo(() => {
    const reportTypes = {
      DRIVE: 'Kjøregodtgjørelse',
      EXPENSE: 'Utlegg',
      TRAVEL: 'Reiseregning',
      EXPENSEGROUP: 'Utleggsgruppe',
    };

    return (
      reports
        ?.sort((a, b) => {
          return new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf();
        })
        ?.filter((report) => report.status === 'PENDING')
        .map((report) => ({
          name: `${report!.account!.user!.givenName} ${report!.account!.user!.familyName}`,
          type: reportTypes[report.type],
          updatedAt: report.updatedAt,
          id: report.id,
          navigate: report.id,
          amount: report.amount,
        })) ?? []
    );
  }, [reports]);

  const theme = useTheme();

  const columns = useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        accessorKey: 'name',
        cell: (value: CellContext<Report, string>) => (
          <DefaultCell className="smalltextbold">{value.getValue()}</DefaultCell>
        ),
        header: () => <HeaderCell>Navn</HeaderCell>,
        size: 100,
      },
      {
        accessorKey: 'type',
        cell: (value: CellContext<Report, string>) => <DefaultCell>{addBreakpoints(value.getValue())}</DefaultCell>,
        header: () => <HeaderCell>Type</HeaderCell>,
        size: 100,
      },
      {
        accessorKey: 'updatedAt',
        cell: (value: CellContext<Report, string>) => (
          <DefaultCell>
            {new Date(value.getValue()).toLocaleDateString('no-NO', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </DefaultCell>
        ),
        header: () => <HeaderCell>Sist innsendt</HeaderCell>,
        size: 100,
      },
      {
        accessorKey: 'amount',
        cell: (value: CellContext<Report, string>) => (
          <DefaultCell>{`${formatAmount(value.getValue() as unknown as number)} kr`}</DefaultCell>
        ),
        header: () => <HeaderCell>Totalt beløp</HeaderCell>,
        size: 100,
      },
    ],
    [],
  );

  return (
    <>
      <div className="flex flex-row justify-between py-5">
        <h1 className="bigheading" style={{ color: theme.label.primary }}>
          Godkjenning
        </h1>
        {filteredReports.length > 0 && (
          <div className="flex flex-row gap-x-4">
            <SearchBar setSearchFilter={setSearchFilter} searchFilter={searchFilter} />
          </div>
        )}
      </div>
      <div className="flex w-full flex-1 flex-col">
        {!isLoading && reports ? (
          <Table
            data={filteredReports}
            filter={searchFilter}
            columns={columns}
            gridCols="minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) 68px"
            sortBy={[{ id: 'updatedAt', desc: true }]}
          />
        ) : (
          <FullscreenLoading />
        )}
      </div>
    </>
  );
}
