import { useAccounts } from 'api/accounts/useAccounts';
import { useDepartments } from 'api/departments/useDepartments';
import { useOrganization } from 'api/organizations/useOrganization';
import BackButton from 'components/buttons/BackButton';
import Input from 'components/input-field/Input';
import { toast } from 'components/toast/Toast';
import { useTheme } from 'context/theme';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { accountService } from 'services/accountService';
import { departmentService } from 'services/departmentService';
import * as Yup from 'yup';
import ManageProjects from './ManageProjects';
import { LargeButton } from 'components/buttons/LargeButton';

export type NewDepFormType = {
  title: string;
  isStaff: boolean;
  projects: { id: string; title: string }[];
  accounts: { id: string; title: string }[];
  addProjectField: string;
};

export function NewDepartment() {
  const { data: organization } = useOrganization();
  const { data: accounts } = useAccounts();
  const { mutate: refreshDepartments } = useDepartments();
  const [changedTitle, setChangedTitle] = useState(true);

  const navigate = useNavigate();

  const theme = useTheme();

  const validationSchema = Yup.object({
    title: Yup.string().required('Påkrevd'),
    isStaff: Yup.boolean(),
  });

  // TODO: Legg til en loadingspinner elns her mens det kommuniseres med serveren
  async function handleSubmit(values: NewDepFormType) {
    const managers = accounts!
      .filter((account) => account.activatedAt)
      .filter((account) => values.accounts.some((selectedAccount) => selectedAccount.id === account.id));
    const illegalManagers = managers.filter((account) => account.role > 2);
    let approved: boolean;
    if (illegalManagers.length > 0) {
      approved = window.confirm(
        'En eller flere av de valgte personene har ikke tilgang til dasbordet. Ved å fortsette vil tilgangen deres endres så de får tilgang til dashbordet.',
      );
    } else {
      approved = true;
    }

    if (!approved) return;

    try {
      await Promise.all(
        illegalManagers.map(async (account) => {
          await accountService.update(account.id, { role: 2 });
        }),
      );

      const newDepartment = await departmentService.create({
        title: values.title,
        isStaff: values.isStaff,
        organizationId: organization?.id ?? '',
      });

      values.accounts.length &&
        (await departmentService.connectAccounts(
          newDepartment.id,
          values.accounts.map((a) => a.id),
        ));

      values.projects.length && (await departmentService.addProjects(newDepartment.id, values.projects));

      refreshDepartments();
      toast.success({ description: 'Avdeling opprettet' });
      navigate('..');
    } catch (error) {
      toast.error({ description: 'Kunne ikke opprette avdeling' });
    }
  }

  if (!organization) return <></>;
  return (
    <div className="flex w-[500px] flex-col gap-y-5">
      <div className="flex flex-col justify-between gap-y-5 pt-5" style={{ color: theme.label.primary }}>
        <BackButton />
        <h1 className="bigheading">Ny avdeling</h1>
      </div>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={
          {
            title: '',
            isStaff: false,
            projects: [],
            accounts: [],
            addProjectField: '',
          } as NewDepFormType
        }>
        {({ errors, values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => {
          return (
            <Form onSubmit={handleSubmit} className="flex flex-col gap-y-4">
              <Input.Text
                label="Tittel"
                value={values.title}
                error={!changedTitle ? errors.title : undefined}
                onChange={(e) => {
                  handleChange('title')(e);
                  setChangedTitle(true);
                }}
                onBlur={handleBlur('title')}
                className="w-60"
              />
              <Input.Toggle
                label="Ansatt"
                description="Denne avdelingen vises kun for ansatte"
                value={values.isStaff}
                onChange={() => setFieldValue('isStaff', !values.isStaff)}
                wrapperClassName="justify-start"
              />
              <ManageProjects />
              <Input.DropdownCheckbox
                label="Ansvarlig"
                description="Legg til personer"
                options={
                  accounts
                    ?.filter((account) => account.activatedAt)
                    ?.map((account) => ({
                      id: account.id,
                      title: `${account.user?.givenName} ${account.user?.familyName}`,
                    })) ?? []
                }
                selectedOptions={values.accounts}
                onRemoveOption={(option) => {
                  setFieldValue(
                    'accounts',
                    values.accounts.filter((el) => el.id !== option.id),
                  );
                }}
                onSelectOption={(option) => {
                  setFieldValue('accounts', values.accounts.concat(option));
                }}
              />
              <div className="mt-6 flex gap-x-4">
                <LargeButton backgroundColor="light" defaultStyle="critical" onClick={() => navigate('..')}>
                  Avbryt
                </LargeButton>
                <div className="h-fit w-fit">
                  <LargeButton
                    // loading={isSubmitting}
                    backgroundColor="light"
                    defaultStyle="white"
                    onClick={() => setChangedTitle(false)}
                    type="submit">
                    Legg til avdeling
                  </LargeButton>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
