import { FloatingPortal } from '@floating-ui/react';
import { useTheme } from 'context/theme';
import { EventEmitter } from 'events';
import { useEffect, useState } from 'react';

export const eventEmitter = new EventEmitter();

export interface BannerProps {
  content: React.ReactNode;
  id: string;
}

export function banner({ content, id }: BannerProps) {
  eventEmitter.emit('showBanner', { content, id });
}

export function hideBanner(id: string) {
  eventEmitter.emit('hideBanner', id);
}

export const BannerContainer = () => {
  const theme = useTheme();
  const [isVisible, setIsVisible] = useState(false);
  const [content, setContent] = useState<React.ReactNode>(null);
  const [navbarElement, setNavbarElement] = useState<HTMLElement | null>(null);
  const [dashboardElement, setDashboardElement] = useState<HTMLElement | null>(null);

  const setElements = () => {
    const navBar = document.getElementById('navbar');
    const dashboard = document.getElementById('dashboard');
    if (navBar) {
      setNavbarElement(navBar);
    }

    if (dashboard) {
      setDashboardElement(dashboard);
    }
  };

  useEffect(() => {
    setElements();

    const showBanner = ({ content }: BannerProps) => {
      setIsVisible(true);
      setContent(content);
    };

    const hideBanner = (id: string) => {
      setIsVisible(false);
    };

    // Subscribe to the 'showBanner' event
    eventEmitter.on('showBanner', showBanner);
    eventEmitter.on('hideBanner', hideBanner);

    // Unsubscribe when the component is unmounted
    return () => {
      eventEmitter.off('showBanner', showBanner);
      eventEmitter.off('hideBanner', hideBanner);
    };
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <FloatingPortal>
      <div
        className="centered fixed left-0 right-0 top-0 z-[10] border-b-[1px] p-2"
        style={{
          backgroundColor: theme.background.info,
          borderColor: theme.border.info,
          left: navbarElement ? navbarElement.getBoundingClientRect().right : 0,
          right: dashboardElement ? window.innerWidth - dashboardElement.getBoundingClientRect().right : 0,
        }}>
        {content}
      </div>
    </FloatingPortal>
  );
};
