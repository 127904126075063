import * as Tabs from '@radix-ui/react-tabs';
import { CellContext } from '@tanstack/react-table';
import { useAccounts } from 'api/accounts/useAccounts';
import { api } from 'api/api';
import { useOrganization } from 'api/organizations/useOrganization';
import { alert } from 'components/alerts/Alert';
import SearchBar from 'components/input-field/SearchBar';
import { FullscreenLoading } from 'components/layout/LoadingIndicator';
import Table from 'components/table/Table';
import { DefaultCell, HeaderCell, TagWrapper } from 'components/table/TableComponents';
import { useTheme } from 'context/theme';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { cn } from 'utils/className';
import { formatPhoneNumber } from 'utils/format';
import { InviteModal } from './invite/InviteModal';
type User = {
  name: string;
  phoneNumber: string;
  email: string;
  role: 'Admin' | 'Bruker' | 'Ingen';
  // appAccess: 'Frivillig' | 'Ansatt';
  id: string;
  navigate: string;
};

type InvitedUser = {
  invitedBy: string;
  role: 'Admin' | 'Bruker' | 'Ingen';
  id: string;
};

export default function Users() {
  const { hash } = useLocation();
  const { data: accounts, isLoading } = useAccounts();
  const [searchFilter, setSearchFilter] = useState('');
  const { data: organization, mutate: refreshOrganization } = useOrganization();

  const theme = useTheme();
  const navigate = useNavigate();

  const activeData: Array<User> = useMemo(
    () =>
      accounts?.map((account) => ({
        name: `${account.user?.givenName} ${account.user?.familyName}`,
        phoneNumber: formatPhoneNumber(account.user?.phoneNumber ?? ''),
        email: account.user?.email ?? '',
        role: ({ 0: 'Admin', 1: 'Admin', 2: 'Dashbord', 3: 'Ingen', 4: 'App' } as any)[account.role],
        id: account.id,
        navigate: `rediger#${account.id}`,
      })) ?? [],
    [accounts],
  );

  const invitedData: Array<InvitedUser> = useMemo(
    () =>
      organization?.invitations
        ?.filter((invitation) => invitation.email || invitation.phoneNumber)
        .map((invitation) => ({
          invitedBy: invitation.phoneNumber || invitation.email,
          role: ({ 0: 'Admin', 1: 'Admin', 2: 'Dashbord', 3: 'Ingen', 4: 'App' } as any)[invitation.role],
          id: invitation.id,
          onClick: () => {
            alert.warning({
              title: 'Slett invitasjon',
              actionLabel: 'Slett',
              description: 'Det vil ikke være mulig å godta invitasjonen etter at den er slettet.',
              onAction: async () => {
                await api.invitations.revokeInviteByToken({
                  token: invitation.token,
                });
                refreshOrganization();
              },
            });
          },
        })) ?? [],
    [organization],
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: 'invitedBy',
        cell: (value: CellContext<User, string>) => (
          <DefaultCell className="smalltextbold">{value.getValue()}</DefaultCell>
        ),
        header: () => <HeaderCell>E-post/telefon</HeaderCell>,
        size: 250,
      },
      {
        accessorKey: 'name',
        cell: (value: CellContext<User, string>) => (
          <DefaultCell className="smalltextbold">{value.getValue()}</DefaultCell>
        ),
        header: () => <HeaderCell>Navn</HeaderCell>,
        size: 250,
      },
      {
        accessorKey: 'email',
        cell: (value: CellContext<User, string>) => <DefaultCell>{value.getValue()}</DefaultCell>,
        header: () => <HeaderCell>E-post</HeaderCell>,
        size: 250,
      },
      {
        accessorKey: 'phoneNumber',
        cell: (value: CellContext<User, string>) => <DefaultCell>{value.getValue()}</DefaultCell>,
        header: () => <HeaderCell>Telefonnummer</HeaderCell>,
        size: 170,
      },
      {
        accessorKey: 'role',
        cell: (role: CellContext<User, string>) => {
          const bgColor = {
            Admin: theme.background.success,
            Dashbord: theme.background.warning,
            App: theme.background.critical,
          }[role.getValue()];
          const borderColor = {
            Admin: theme.border.success,
            Dashbord: theme.border.warning,
            App: theme.border.critical,
          }[role.getValue()];
          return (
            <TagWrapper>
              <span
                className="smalltext rounded-small px-4 py-2"
                style={{ color: theme.label.primary, backgroundColor: bgColor, borderWidth: 1, borderColor }}>
                {role.getValue()}
              </span>
            </TagWrapper>
          );
        },
        header: () => <HeaderCell>Rolle</HeaderCell>,
        size: 100,
      },
    ],
    [],
  );

  return (
    <>
      <div className="flex flex-row justify-between pt-5">
        <h1 className="bigheading" style={{ color: theme.label.primary }}>
          Brukere
        </h1>
        <div className="flex flex-row gap-x-4">
          {((hash.slice(1) === 'inviterte' && invitedData.length > 0) ||
            (hash.slice(1) !== 'inviterte' && activeData.length > 0)) && (
            <SearchBar setSearchFilter={setSearchFilter} searchFilter={searchFilter} />
          )}
          <InviteModal isOpen={hash.slice(1) === 'ny'} />
        </div>
      </div>
      <Tabs.Root
        className="TabsRoot flex flex-1 flex-col gap-y-5"
        defaultValue="aktive"
        value={hash.slice(1) !== 'inviterte' ? 'aktive' : 'inviterte'}
        onValueChange={(value) => navigate(`#${value}`)}>
        <Tabs.List
          className="TabsList smalltext flex gap-x-2"
          style={{ color: theme.label.primary }}
          aria-label="Manage your account">
          <Tabs.Trigger
            className={cn('TabsTrigger rounded-small px-4 py-2 data-[state=inactive]:border-[1px]')}
            style={
              hash.slice(1) !== 'inviterte'
                ? { backgroundColor: theme.background.inverse, color: theme.label.ondark }
                : { backgroundColor: theme.background.primary, borderColor: theme.border.border }
            }
            value="aktive">
            Aktive
          </Tabs.Trigger>
          <Tabs.Trigger
            className={cn('TabsTrigger rounded-small px-4 py-2 data-[state=inactive]:border-[1px]')}
            style={
              hash.slice(1) === 'inviterte'
                ? { backgroundColor: theme.background.inverse, color: theme.label.ondark }
                : { backgroundColor: theme.background.primary, borderColor: theme.border.border }
            }
            value="inviterte">
            Inviterte
          </Tabs.Trigger>
        </Tabs.List>
        <Tabs.Content className="TabsContent flex-1" value="aktive">
          <div className="flex h-full w-full flex-col">
            {!isLoading && accounts ? (
              <Table
                data={activeData}
                filter={searchFilter}
                columns={
                  organization?.subscription?.subscriptionTier.dashboard
                    ? columns.slice(1)
                    : [...columns.slice(1, 3), columns[4]]
                }
                gridCols="minmax(0, 5fr) minmax(0, 7fr) minmax(150px, 4fr) minmax(96px, 2fr) 68px"
                sortBy={[{ id: 'name', desc: false }]}
              />
            ) : (
              <FullscreenLoading />
            )}
          </div>
        </Tabs.Content>
        <Tabs.Content className="TabsContent flex-1" value="inviterte">
          <div className="flex h-full w-full flex-col">
            {!isLoading && accounts ? (
              <Table
                data={invitedData}
                filter={searchFilter}
                columns={
                  organization?.subscription?.subscriptionTier.dashboard ? [columns[0], columns[4]] : [columns[0]]
                }
                gridCols="minmax(0, 1fr) minmax(0, 1fr) 68px"
                sortBy={[{ id: 'invitedBy', desc: false }]}
              />
            ) : (
              <FullscreenLoading />
            )}
          </div>
        </Tabs.Content>
      </Tabs.Root>
    </>
  );
}
