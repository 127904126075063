/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum DigestRateEnum {
  Daily = "daily",
  Weekly = "weekly",
  Monthly = "monthly",
}

export interface TriggerDigestDto {
  digestRate: DigestRateEnum;
  apiKey: string;
}

export interface CreateAccountDto {
  /** Id of user linked to the account */
  userId: string;
  /**
   * Organization id - a UUIDv4 string
   * @example "6c49b764-fe3b-4c98-8c98-1a1042606253"
   */
  organizationId: string;
  /**
   * Role
   * Admin = 1, Dashboard access = 2, User = 4
   */
  role?: number;
  email?: string;
  isStaff?: boolean;
}

export enum RateLevelTypeEnum {
  ALL = "ALL",
  TAXABLE = "TAXABLE",
  TAX_FREE = "TAX_FREE",
  NONE = "NONE",
}

export enum SubscriptionPaymentMethodType {
  CARD = "CARD",
  INVOICE = "INVOICE",
  NONE = "NONE",
}

export enum SubscriptionStatusTypeEnum {
  Active = "active",
  PastDue = "past_due",
  Unpaid = "unpaid",
  Canceled = "canceled",
  Incomplete = "incomplete",
  IncompleteExpired = "incomplete_expired",
  Trialing = "trialing",
  Paused = "paused",
}

export interface SubscriptionTierPrice {
  type: SubscriptionTierPriceTypeEnum;
  recurringInterval: SubscriptionTierPriceRecurringIntervalEnum;
  isDefault: boolean;
  id: string;
  stripePriceId: string;
  amount: number;
  recurringIntervalCount: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  subscriptionTierId: string;
}

export interface SubscriptionTierFeature {
  subscriptionTierId: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  id: string;
  title: string;
  description: string;
}

export interface CouponCode {
  id: string;
  stripeCouponCodeId: string;
  code: string;
  active: boolean;
  /** @format date-time */
  expiresAt: string;
  maxRedemptions: number;
  redeemedCount: number;
  firstTimeRedemptionOnly: boolean;
  couponId: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  organizationId: string;
}

export interface Coupon {
  id: string;
  stripeCouponId: string;
  name: string;
  duration: object;
  durationInMonths: number;
  percentOff: number;
  /** @format date-time */
  expiresAt: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  couponCodes?: CouponCode[];
}

export interface SubscriptionTier {
  isDefaultFree: boolean;
  isDefaultPaid: boolean;
  projectsLimit: number;
  archive: boolean;
  travels: boolean;
  drives: boolean;
  internalExternal: boolean;
  chat: boolean;
  approvalFlow: boolean;
  customLogo: boolean;
  tollCalculation: boolean;
  ehf: boolean;
  slack: boolean;
  dashboard: boolean;
  id: string;
  stripeProductId: string;
  name: string;
  description: string;
  usersLimit: number;
  totalExpensesLimit: number;
  categoriesLimit: number;
  departmentsLimit: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  prices?: SubscriptionTierPrice[];
  isPublic: boolean;
  features?: SubscriptionTierFeature[];
  coupons?: Coupon[];
}

export interface StripeInvoiceLine {
  id: string;
  description: string;
  amount: number;
}

export interface StripeInvoice {
  created: number;
  amount_due: number;
  subtotal: number;
  lines: {
    data: StripeInvoiceLine[];
  };
  paid: boolean;
  hosted_invoice_url: string;
}

export interface Subscription {
  paymentMethod: SubscriptionPaymentMethodType;
  subscriptionStatus: SubscriptionStatusTypeEnum;
  /** @format date-time */
  canceledAt: string;
  id: string;
  stripeSubscriptionId: string;
  subscriptionTierId: string;
  subscriptionTier: SubscriptionTier;
  /** @format date-time */
  billingPeriodStart: string;
  /** @format date-time */
  billingPeriodEnd: string;
  priceId: string;
  price: SubscriptionTierPrice;
  organizationId: string;
  cancelAtPeriodEnd: boolean;
  activeCouponId: string;
  activeCoupon: Coupon;
  /** @format date-time */
  trialPeriodEnd: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  stripeInvoices: {
    invoices: StripeInvoice[];
    upcoming: StripeInvoice;
  };
  stripeCustomer: {
    invoice_settings?: {
      default_payment_method?: {
        card?: {
          brand: string;
          last4: string;
        };
      };
    };
  };
}

export interface Usage {
  id: string;
  organizationId: string;
  usersCount: number;
  expensesCount: number;
  drivesCount: number;
  travelsCount: number;
  categoriesCount: number;
  departmentsCount: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
}

export enum TaxableRateCriteriaEnum {
  FROM6TO12HOURS = "FROM_6_TO_12_HOURS",
  OVER12HOURSWITHOUTACCOMMODATION = "OVER_12_HOURS_WITHOUT_ACCOMMODATION",
  OVER12HOURSWITHACCOMMODATION = "OVER_12_HOURS_WITH_ACCOMMODATION",
  MILEAGE = "MILEAGE",
  MILEAGE_WITH_PASSENGER = "MILEAGE_WITH_PASSENGER",
  MILEAGE_WITH_TRAILER = "MILEAGE_WITH_TRAILER",
  MILEAGE_OFFROAD = "MILEAGE_OFFROAD",
}

export interface Rate {
  criteria: TaxableRateCriteriaEnum;
  id: string;
  title: string;
  rate: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  deletedAt: string;
  organizationId: string;
}

export enum CategoryTypeEnum {
  EXPENSE = "EXPENSE",
  DRIVE = "DRIVE",
  DIET = "DIET",
  OTHER = "OTHER",
}

export enum ExpenseTypeTypeEnum {
  DRIVE = "DRIVE",
  DIET = "DIET",
  DEFAULT = "DEFAULT",
}

export enum ExpensePaymentMethodTypeEnum {
  CARD = "CARD",
  INVOICE = "INVOICE",
}

export interface Drive {
  id: string;
  from: string;
  to: string;
  fromPlaceId: string;
  toPlaceId: string;
  distance: number;
  returnTrip: boolean;
  toll: number;
  driveRate: number;
  vehicle: string;
  shouldCalculateToll: boolean;
  expenseId: string;
  expense?: Expense;
}

export interface Diet {
  id: string;
  /** @format date-time */
  date: string;
  isHalfDay: boolean;
  amount: number;
  fullFareAmount: number;
  breakfast: boolean;
  lunch: boolean;
  dinner: boolean;
  criteria: string;
  taxFreeAmount: number;
  expenseId: string;
  expense?: Expense;
}

export enum ReportTypeTypeEnum {
  TRAVEL = "TRAVEL",
  EXPENSE = "EXPENSE",
  DRIVE = "DRIVE",
  EXPENSEGROUP = "EXPENSEGROUP",
}

export enum ReportStatusTypeEnum {
  DRAFT = "DRAFT",
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  DELETED = "DELETED",
}

export enum TravelAccommodationTypeEnum {
  HOTEL = "HOTEL",
  COOKING_AVAILABLE = "COOKING_AVAILABLE",
  COOKING_UNAVAILABLE = "COOKING_UNAVAILABLE",
  NONE = "NONE",
}

export enum TravelRateLevelTypeEnum {
  NONE = "NONE",
  TAX_FREE = "TAX_FREE",
  TAXABLE = "TAXABLE",
  CUSTOM = "CUSTOM",
}

export interface Travel {
  typeOfAccommodation: TravelAccommodationTypeEnum;
  rateLevel: TravelRateLevelTypeEnum;
  id: string;
  fromLocationCity: string;
  toLocationCity: string;
  fromLocationCountry: string;
  toLocationCountry: string;
  fromLocationRegion: string;
  toLocationRegion: string;
  /** @format date-time */
  fromDateTime: string;
  /** @format date-time */
  toDateTime: string;
  fromLocationName: string;
  toLocationName: string;
  fromLocationPlaceId: string;
  toLocationPlaceId: string;
  purpose: string;
  advanceAmount: number;
  reportId: string;
  report?: Report;
}

export enum InvoiceStatusTypeEnum {
  CREATING = "CREATING",
  CREATED = "CREATED",
  SENDING = "SENDING",
  SENT = "SENT",
  DELIVERED = "DELIVERED",
  FAILED = "FAILED",
}

export interface Invoice {
  status: InvoiceStatusTypeEnum;
  invoiceNumber: number;
  deliveredAs: string;
  errorDescription: string;
  ehfMessageNumber: number;
  pdfStorageKey: string;
  ehfStorageKey: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  reportId: string;
  organizationId: string;
  id: string;
  /** @format date-time */
  dueDate: string;
  url?: string;
  ehfUrl?: string;
  orgNumber: string;
}

export interface Report {
  type: ReportTypeTypeEnum;
  status: ReportStatusTypeEnum;
  id: string;
  title: string;
  amount: number;
  description: string;
  accountId: string;
  organizationId: string;
  rejectReason: string;
  reviewedByAccountId: string;
  departmentId: string;
  projectId: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  deletedAt: string | null;
  expenses?: Expense[];
  account?: Account;
  reviewedBy?: Account;
  organization?: Organization;
  travel?: Travel;
  invoices?: Invoice[];
  department?: Department;
  project?: Project;
}

export interface Project {
  id: string;
  title: string;
  accountingCode: string;
  accountingDescription: string;
  description: string;
  isStaff: boolean;
  departmentId: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  deletedAt: string;
  department?: Department;
  expenses?: Expense[];
  reports?: Report[];
}

export interface DepartmentConfiguration {
  id: string;
  shouldOverrideInvoiceBuyer: boolean;
  invoiceLegalName: string;
  invoiceOrgNumber: string;
  invoiceAddress: string;
  invoicePostalCode: string;
  invoiceRegion: string;
  invoicePaymentTerms: number;
  deliverByEhf: boolean;
  deliverByEmail: boolean;
  deliveryEmail: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  departmentId: string;
  department?: Department;
}

export interface Department {
  id: string;
  title: string;
  accountingCode: string;
  accountingDescription: string;
  description: string;
  isStaff: boolean;
  organizationId: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  deletedAt: string;
  projects?: Project[];
  expenses?: Expense[];
  accounts?: Account[];
  organization?: Organization;
  configuration?: DepartmentConfiguration;
}

export interface Ocr {
  id: string;
  amount: number;
  /** @format date-time */
  date: string;
  merchant: string;
  attachmentId: string;
  attachment?: Attachment;
}

export interface Attachment {
  id: string;
  storageKey: string;
  expenseId: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  expense?: Expense;
  ocr?: Ocr;
}

export interface Expense {
  type: ExpenseTypeTypeEnum;
  paymentMethod: ExpensePaymentMethodTypeEnum;
  id: string;
  title: string;
  /** @format date-time */
  date: string;
  amount: number;
  description: string;
  departmentId: string;
  projectId: string;
  reportId: string;
  accountId: string;
  organizationId: string;
  categoryId: string;
  rejectReason: string;
  paymentCardId: string | null;
  currency: string;
  exchangeRate: number;
  /** @format date-time */
  deletedAt: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  drive?: Drive;
  diets?: Diet[];
  department?: Department;
  project?: Project;
  category?: Category;
  attachments?: Attachment[];
  report?: Report;
  account?: Account;
}

export interface Category {
  type: CategoryTypeEnum;
  /** @format date-time */
  deletedAt: string;
  id: string;
  title: string;
  reference: string;
  accountingCode: string;
  accountingDescription: string;
  description: string;
  expenses?: Expense[];
  organizationId: string;
  organization?: Organization;
  isStaff: boolean;
}

export interface Invitation {
  id: string;
  token: string;
  email: string;
  phoneNumber: string;
  role: number;
  /** @format date-time */
  expiresAt: string;
  invitedCount: number;
  redeemedCount: number;
  /** @format date-time */
  createdAt: string;
  createdByUserId: string;
  createdByUser: User;
  /** @format date-time */
  revokedAt: string;
  revokedByUserId: string;
  invalidRedemtionCount: number;
  organizationId: string;
  organization?: Organization;
  departments?: Department[];
}

export interface Organization {
  activeRates: RateLevelTypeEnum;
  id: string;
  displayName: string;
  legalName: string;
  handle: string;
  orgNumber: string;
  invoiceEmail: string;
  isCategoryRequired: boolean;
  isDepartmentRequired: boolean;
  isProjectRequired: boolean;
  address: string;
  postalCode: string;
  region: string;
  driveRate: number;
  shortDriveRate: number;
  shortDriveLimit: number;
  invoiceAsEhf: boolean;
  isVatRegistered: boolean;
  contactPerson: string;
  contactPersonEmail: string;
  paymentTerms: number;
  iconStorageKey: string;
  iconUrl?: string;
  isTravelActive: boolean;
  isDriveActive: boolean;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  isApprovalFlowActive: boolean;
  stripeCustomerId: string;
  subscription?: Subscription;
  usage?: Usage;
  rates?: Rate[];
  categories?: Category[];
  invitations?: Invitation[];
}

export interface Account {
  email: string;
  id: string;
  role: number;
  digest: number;
  isNotificationsEnabled: boolean;
  isStaff: boolean;
  isBlocked: boolean;
  userId: string;
  organizationId: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  createdBy: string;
  /** @format date-time */
  deletedAt: string;
  slackInstallationId: string;
  user?: User;
  organization?: Organization;
  departments?: Department[];
}

export interface PaymentCard {
  id: string;
  last4: string;
  brand: string;
  isDefault: boolean;
  description: string;
  organizationId: string | null;
  userId: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  deletedAt: string;
}

export interface PushToken {
  id: string;
  token: string;
  allowNotifications: boolean;
  userId: string;
}

export interface EmailInbox {
  id: string;
  email: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  userId: string;
}

export interface User {
  id: string;
  vippsId: string;
  role: number;
  email: string;
  givenName: string;
  familyName: string;
  streetAddress: string;
  postalCode: string;
  region: string;
  phoneNumber: string;
  /** @format date-time */
  birthdate: string;
  accountNumber: string;
  passwordHash: string;
  resetToken: string;
  /** @format date-time */
  resetTokenExpires: string;
  /** @format date-time */
  passwordReset: string;
  isBlocked: boolean;
  isNotificationsEnabled: boolean;
  isOnboardingCompleted: boolean;
  isCustomMarketingConsentGiven: boolean;
  isNewsletterConsentGiven: boolean;
  isEmailVerified: boolean;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  lastLogin: string;
  /** @format date-time */
  deletedAt: string;
  accounts?: Account[];
  refreshToken?: string;
  paymentCards?: PaymentCard[];
  reports?: Report[];
  pushTokens?: PushToken[];
  emailInbox?: EmailInbox;
  invitationsCreated?: Invitation[];
  invitationsRevoked?: Invitation[];
  intercomWebHash?: string;
  intercomIosHash?: string;
  intercomAndroidHash?: string;
}

export interface UpdateAccountDto {
  isStaff?: boolean;
  role?: number;
  digest?: number;
  email?: string;
}

export enum EventSubjectTypeEnum {
  ACCOUNT = "ACCOUNT",
  CATEGORY = "CATEGORY",
  DEPARTMENT = "DEPARTMENT",
  EXPENSE = "EXPENSE",
  ORGANIZATION = "ORGANIZATION",
  PROJECT = "PROJECT",
  REPORT = "REPORT",
  USER = "USER",
}

export enum EventTypeEnum {
  CREATED = "CREATED",
  UPDATED = "UPDATED",
  DELETED = "DELETED",
}

export interface UpdateHistoryDto {
  eventSubjectType?: EventSubjectTypeEnum;
  eventType?: EventTypeEnum;
  state?: string;
  eventSubjectId?: string;
  authorId?: string;
}

export interface StartSubscriptionDto {
  subscriptionTierId?: string;
  priceId?: string;
  couponCodeId?: string;
  trial?: boolean;
}

export interface ChangeSubscriptionTierDto {
  subscriptionTierId: string;
  priceId?: string;
}

export interface ChangeSubscriptionPriceDto {
  priceId: string;
}

export interface ApplyCouponDto {
  couponId: string;
  couponCodeId?: string;
}

export interface ChangePaymentMethodDto {
  paymentMethod: SubscriptionPaymentMethodType;
}

export interface CreatePriceDto {
  amount: number;
  /** @default "MONTH" */
  recurringInterval: object;
  /** @default 1 */
  recurringIntervalCount: number;
  /** @default "PER_USER" */
  type?: object;
}

export interface CreateSubscriptionTierDto {
  name: string;
  description: string;
  /** @default 99999 */
  usersLimit: number;
  /** @default 99999 */
  totalExpensesLimit: number;
  /** @default 99999 */
  categoriesLimit: number;
  /** @default 99999 */
  departmentsLimit: number;
  /** @default true */
  travels: boolean;
  /** @default true */
  drives: boolean;
  /** @default true */
  internalExternal: boolean;
  /** @default true */
  chat: boolean;
  /** @default true */
  slack: boolean;
  /** @default true */
  tollCalculation: boolean;
  /** @default true */
  approvalFlow: boolean;
  /** @default true */
  archive: boolean;
  /** @default true */
  customLogo: boolean;
  /** @default true */
  dashboard: boolean;
  price: CreatePriceDto;
  isPublic: boolean;
}

export interface UpdateSubscriptionTierDto {
  name?: string;
  description?: string;
  usersLimit?: number;
  totalExpensesLimit?: number;
  categoriesLimit?: number;
  departmentsLimit?: number;
  travels?: boolean;
  drives?: boolean;
  internalExternal?: boolean;
  chat?: boolean;
  slack?: boolean;
  /** @default true */
  tollCalculation: boolean;
  /** @default true */
  approvalFlow: boolean;
  /** @default true */
  archive: boolean;
  /** @default true */
  customLogo: boolean;
  /** @default true */
  dashboard: boolean;
  isPublic: boolean;
}

export enum SubscriptionTierTypeEnum {
  Free = "free",
  Paid = "paid",
}

export interface CreateCouponDto {
  /** List of subscriptionTier ids the coupon applies to. If not provided, the coupon applies to all subscriptionTiers. */
  appliesTo?: string[];
  name: string;
  duration: object;
  durationInMonths?: number;
  percentOff: number;
  /** @format date-time */
  expiresAt?: string;
}

export interface UpdateCouponDto {
  name: string;
}

export interface CreateCouponCodeDto {
  code: string;
  /** @format date-time */
  expiresAt?: string;
  maxRedemptions?: number;
  firstTimeRedemptionOnly?: boolean;
  organizationId?: string;
}

export interface UpdateCouponCodeDto {
  active?: boolean;
}

export interface CreateOrganizationDto {
  activeRates?: RateLevelTypeEnum;
  legalName: string;
  displayName: string;
  handle?: string;
  orgNumber?: string;
  address?: string;
  postalCode?: string;
  region?: string;
  invoiceEmail?: string;
  id?: string;
  isCategoryRequired?: boolean;
  isDepartmentRequired?: boolean;
  isProjectRequired?: boolean;
  driveRate?: number;
  shortDriveRate?: number;
  shortDriveLimit?: number;
  invoiceAsEhf?: boolean;
  isVatRegistered?: boolean;
  contactPerson?: string;
  contactPersonEmail?: string;
  paymentTerms?: number;
  iconStorageKey?: string;
  iconUrl?: string;
  isTravelActive?: boolean;
  isDriveActive?: boolean;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  isApprovalFlowActive?: boolean;
  stripeCustomerId?: string;
  subscription?: Subscription;
  usage?: Usage;
  rates?: Rate[];
  categories?: Category[];
  invitations?: Invitation[];
}

export interface UpdateOrganizationDto {
  activeRates?: RateLevelTypeEnum;
  shortDriveLimit?: number;
  shortDriveRate?: number;
  driveRate?: number;
  invoiceAsEhf?: boolean;
  invoiceEmail?: string;
  paymentTerms?: number;
  driveCategoryId?: string;
  contactPerson?: string;
  isTravelActive?: boolean;
  isDriveActive?: boolean;
  isApprovalFlowActive?: boolean;
  isProjectRequired?: boolean;
  isCategoryRequired?: boolean;
  stripeCustomerId?: string;
  id?: string;
  displayName?: string;
  legalName?: string;
  handle?: string;
  orgNumber?: string;
  isDepartmentRequired?: boolean;
  address?: string;
  postalCode?: string;
  region?: string;
  isVatRegistered?: boolean;
  contactPersonEmail?: string;
  iconStorageKey?: string;
  iconUrl?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  subscription?: Subscription;
  usage?: Usage;
  rates?: Rate[];
  categories?: Category[];
  invitations?: Invitation[];
}

export interface UpdateRateDto {
  rate: number;
}

export interface CreateCategoryDto {
  type?: CategoryTypeEnum;
  title: string;
  reference?: string;
  accountingCode?: string;
  accountingDescription?: string;
  description?: string;
  isStaff?: boolean;
  organizationId: string;
}

export interface UpdateCategoryDto {
  type?: CategoryTypeEnum;
  title?: string;
  reference?: string;
  accountingCode?: string;
  accountingDescription?: string;
  description?: string;
  isStaff?: boolean;
  organizationId?: string;
}

export interface Address {
  streetAddress?: string;
  region?: string;
  postalCode?: string;
  country?: string;
}

export interface CreateUserDto {
  vippsId?: string;
  address?: Address;
  /** @format date-time */
  birthdate?: string;
  givenName?: string;
  familyName?: string;
  phoneNumber: string;
  email?: string;
  password?: string;
  accountNumber?: string;
  isEmailVerified?: boolean;
}

export interface InboxAttachment {
  id: string;
  storageKey: string;
  emailInboxId: string;
  senderEmail: string;
  senderName: string;
  amount: number;
  currency: string;
  /** @format date-time */
  date: string;
  merchant: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  url?: string;
}

export interface UpdateUserDto {
  /** @format date-time */
  passwordReset?: string;
  /** @format date-time */
  resetTokenExpires?: string;
  resetToken?: string;
  streetAddress?: string;
  region?: string;
  postalCode?: string;
  isCustomMarketingConsentGiven?: boolean;
  isNotificationsEnabled?: boolean;
  isOnboardingCompleted?: boolean;
  isNewsletterConsentGiven?: boolean;
  /** @format date-time */
  lastLogin?: string;
  accountNumber?: string;
  email?: string;
  password?: string;
}

export interface AddPaymentCardDto {
  last4: string;
  brand: string;
  description: string;
  isDefault: boolean;
  organizationId: string | null;
}

export interface UpdatePaymentCardDto {
  last4?: string;
  brand?: string;
  description?: string;
  isDefault?: boolean;
  organizationId?: string | null;
}

export interface AddPushTokenDto {
  token: string;
}

export interface UpsertInboxDto {
  email: string;
}

export interface AddInboxAttachmentDto {
  recipient: string;
  senderEmail: string;
  senderName?: string;
}

export interface RegisterDto {
  givenName: string;
  familyName: string;
  birthdate: string;
  email: string;
  phoneNumber: string;
  isEmailVerified: boolean;
  vippsId: string;
  streetAddress: string;
  postalCode: string;
  region: string;
}

export interface TokensDto {
  accessToken: string;
  refreshToken: string;
}

export interface LoginDto {
  username: string;
  password: string;
}

export interface LogoutDto {
  refreshToken?: string;
  pushToken?: string;
}

export interface RefreshDto {
  refreshToken: string;
}

export interface TestUserLoginDto {
  password: string;
}

export interface DepartmentConfigurationDto {
  invoicePaymentTerms?: number;
  deliverByEhf?: boolean;
  deliverByEmail?: boolean;
  deliveryEmail?: string;
  shouldOverrideInvoiceBuyer?: boolean;
  invoiceOrgNumber?: string;
  invoiceLegalName?: string;
  invoiceAddress?: string;
  invoiceRegion?: string;
  invoicePostalCode?: string;
}

export interface CreateDepartmentDto {
  title: string;
  isStaff?: boolean;
  organizationId: string;
  configuration?: DepartmentConfigurationDto;
}

export interface UpdateDepartmentDto {
  title?: string;
  isStaff?: boolean;
  organizationId?: string;
  configuration?: DepartmentConfigurationDto;
}

export interface ConnectOrDisconnectAccountsDto {
  accountIds: string[];
}

export interface RemoveProjectsDto {
  projectIds: string[];
}

export interface CreateTravelDto {
  typeOfAccommodation: TravelAccommodationTypeEnum;
  rateLevel: TravelRateLevelTypeEnum;
  /** @format date-time */
  fromDateTime: string;
  /** @format date-time */
  toDateTime: string;
  fromLocationName: string;
  toLocationName: string;
  fromLocationPlaceId: string;
  toLocationPlaceId: string;
  fromLocationCity: string;
  toLocationCity: string;
  fromLocationCountry: string;
  toLocationCountry: string;
  fromLocationRegion: string;
  toLocationRegion: string;
  purpose: string;
  advanceAmount?: number;
}

export interface CreateReportDto {
  type: ReportTypeTypeEnum;
  id?: string;
  title?: string;
  accountId: string;
  description?: string;
  organizationId: string;
  departmentId?: string;
  projectId?: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  travel?: CreateTravelDto;
}

export interface UpdateReportDto {
  type?: ReportTypeTypeEnum;
  id?: string;
  title?: string;
  accountId?: string;
  description?: string;
  organizationId?: string;
  departmentId?: string;
  projectId?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  travel?: CreateTravelDto;
}

export interface CreateInvoiceDto {
  reportId: string;
}

export type SendInvoiceDto = object;

export interface DriveDto {
  id?: string;
  from: string;
  to: string;
  fromPlaceId: string;
  toPlaceId: string;
  distance: number;
  returnTrip?: boolean;
  toll: number;
  driveRate: number;
  vehicle: string;
  shouldCalculateToll: boolean;
}

export interface UpsertExpenseDto {
  type: ExpenseTypeTypeEnum;
  paymentMethod?: ExpensePaymentMethodTypeEnum;
  id: string;
  accountId: string;
  reportId: string;
  /** @format date-time */
  date?: string;
  amount?: number;
  description?: string;
  projectId?: string;
  departmentId?: string;
  categoryId?: string;
  drive?: DriveDto;
  paymentCardId?: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  deletedAt?: string;
  currency?: string;
  exchangeRate?: number;
}

export interface ReviewExpenseDto {
  rejectReason: string;
}

export interface UpsertDietDto {
  breakfast: boolean;
  lunch: boolean;
  dinner: boolean;
  amount: number;
}

export interface AttachmentResponseDto {
  id: string;
  storageKey: string;
  expenseId: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  expense?: Expense;
  ocr?: Ocr;
  url: string;
}

export interface OcrReponseDto {
  amount: number;
  date: string;
  merchant: string;
}

export interface RequestDate {
  year: number;
  month: number;
  day: number;
}

export interface ExchangeRateDto {
  currency: string;
  date: RequestDate;
}

export interface InviteByEmailDto {
  emails: string[];
  /** @format date-time */
  expiresAt?: string;
  role?: number;
  /** @min 1 */
  invitedCount?: number;
  organizationId: string;
  departmentIds?: string[];
}

export interface InviteByPhoneDto {
  phoneNumbers: string[];
  /** @format date-time */
  expiresAt?: string;
  role?: number;
  /** @min 1 */
  invitedCount?: number;
  organizationId: string;
  departmentIds?: string[];
}

export interface InviteByLinkDto {
  /** @format date-time */
  expiresAt?: string;
  role?: number;
  /** @min 1 */
  invitedCount?: number;
  organizationId: string;
  departmentIds?: string[];
}

export interface RevokeInviteTokenDto {
  token: string;
}

export interface AcceptInviteTokenDto {
  token: string;
}

export interface GetInviteTokenDto {
  token: string;
}

export enum SubscriptionTierPriceTypeEnum {
  FIXED = "FIXED",
  PER_USER = "PER_USER",
}

export enum SubscriptionTierPriceRecurringIntervalEnum {
  MONTH = "MONTH",
  YEAR = "YEAR",
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "http://localhost:4000";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: FormData) =>
      (Array.from(input.keys()) || []).reduce((formData, key) => {
        const property = input.get(key);
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Utleggsappen API
 * @version 1.1
 * @baseUrl http://localhost:4000
 * @contact
 *
 * API services for Utleggsappen and utlegg.app
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  health = {
    /**
     * No description
     *
     * @name GetHealth
     * @request GET:/health
     * @response `200` `string`
     */
    getHealth: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/health`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  appDevMode = {
    /**
     * No description
     *
     * @name GetAppDevMode
     * @request GET:/app-dev-mode
     * @response `200` `boolean`
     */
    getAppDevMode: (params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/app-dev-mode`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  tasks = {
    /**
     * No description
     *
     * @tags Tasks
     * @name Digest
     * @request POST:/tasks/digest
     * @response `201` `string`
     */
    digest: (data: TriggerDigestDto, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/tasks/digest`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  slack = {
    /**
     * No description
     *
     * @name Callback
     * @request GET:/slack/callback
     * @response `200` `SendInvoiceDto`
     */
    callback: (params: RequestParams = {}) =>
      this.request<SendInvoiceDto, any>({
        path: `/slack/callback`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetInstallUrl
     * @request GET:/slack/installurl/{accountId}
     * @response `200` `string`
     */
    getInstallUrl: (
      accountId: string,
      query: {
        redirect: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/slack/installurl/${accountId}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name Uninstall
     * @request GET:/slack/uninstall/{accountId}
     * @secure
     * @response `200` `void`
     */
    uninstall: (accountId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/slack/uninstall/${accountId}`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
  accounts = {
    /**
     * @description Create an account connecting a user to an organization
     *
     * @tags Accounts
     * @name Create
     * @request POST:/accounts
     * @secure
     * @response `201` `SendInvoiceDto`
     */
    create: (data: CreateAccountDto, params: RequestParams = {}) =>
      this.request<SendInvoiceDto, any>({
        path: `/accounts`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Accounts
     * @name FindAll
     * @request GET:/accounts
     * @secure
     * @response `200` `(Account)[]`
     */
    findAll: (
      query: {
        organizationId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<Account[], any>({
        path: `/accounts`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Accounts
     * @name FindOne
     * @request GET:/accounts/{id}
     * @secure
     * @response `200` `Account`
     */
    findOne: (id: string, params: RequestParams = {}) =>
      this.request<Account, any>({
        path: `/accounts/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Accounts
     * @name Update
     * @request PATCH:/accounts/{id}
     * @secure
     * @response `200` `void`
     */
    update: (id: string, data: UpdateAccountDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/accounts/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Accounts
     * @name Remove
     * @request DELETE:/accounts/{id}
     * @secure
     * @response `200` `SendInvoiceDto`
     */
    remove: (id: string, params: RequestParams = {}) =>
      this.request<SendInvoiceDto, any>({
        path: `/accounts/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  history = {
    /**
     * No description
     *
     * @tags History
     * @name FindAll
     * @request GET:/history
     * @secure
     * @response `200` `void`
     */
    findAll: (
      query: {
        eventSubjectType: EventSubjectTypeEnum;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/history`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags History
     * @name FindOne
     * @request GET:/history/{id}
     * @secure
     * @response `200` `void`
     */
    findOne: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/history/${id}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags History
     * @name Update
     * @request PATCH:/history/{id}
     * @secure
     * @response `200` `void`
     */
    update: (id: string, data: UpdateHistoryDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/history/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags History
     * @name Remove
     * @request DELETE:/history/{id}
     * @secure
     * @response `200` `void`
     */
    remove: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/history/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  subscriptions = {
    /**
     * No description
     *
     * @tags Subscriptions
     * @name FindOneByOrganizationId
     * @request GET:/subscriptions/{organizationId}
     * @secure
     * @response `200` `Subscription`
     */
    findOneByOrganizationId: (organizationId: string, params: RequestParams = {}) =>
      this.request<Subscription, any>({
        path: `/subscriptions/${organizationId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscriptions
     * @name StartSubscription
     * @request POST:/subscriptions/{organizationId}/start
     * @secure
     * @response `201` `void`
     */
    startSubscription: (organizationId: string, data: StartSubscriptionDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/subscriptions/${organizationId}/start`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscriptions
     * @name StartTrial
     * @request POST:/subscriptions/{organizationId}/start-trial
     * @secure
     * @response `201` `void`
     */
    startTrial: (organizationId: string, data: StartSubscriptionDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/subscriptions/${organizationId}/start-trial`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscriptions
     * @name EndSubscription
     * @request POST:/subscriptions/{organizationId}/end
     * @secure
     * @response `201` `void`
     */
    endSubscription: (organizationId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/subscriptions/${organizationId}/end`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscriptions
     * @name CancelSubscription
     * @request POST:/subscriptions/{organizationId}/cancel
     * @secure
     * @response `201` `void`
     */
    cancelSubscription: (organizationId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/subscriptions/${organizationId}/cancel`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscriptions
     * @name ResumeSubscription
     * @request POST:/subscriptions/{organizationId}/resume
     * @secure
     * @response `201` `void`
     */
    resumeSubscription: (organizationId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/subscriptions/${organizationId}/resume`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscriptions
     * @name ChangeSubscriptionTier
     * @request POST:/subscriptions/{organizationId}/change-tier
     * @secure
     * @response `201` `void`
     */
    changeSubscriptionTier: (organizationId: string, data: ChangeSubscriptionTierDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/subscriptions/${organizationId}/change-tier`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscriptions
     * @name ChangeSubscriptionPrice
     * @request POST:/subscriptions/{organizationId}/change-price
     * @secure
     * @response `201` `void`
     */
    changeSubscriptionPrice: (organizationId: string, data: ChangeSubscriptionPriceDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/subscriptions/${organizationId}/change-price`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscriptions
     * @name ApplyCoupon
     * @request POST:/subscriptions/{organizationId}/apply-coupon
     * @secure
     * @response `201` `SendInvoiceDto`
     */
    applyCoupon: (organizationId: string, data: ApplyCouponDto, params: RequestParams = {}) =>
      this.request<SendInvoiceDto, any>({
        path: `/subscriptions/${organizationId}/apply-coupon`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscriptions
     * @name RemoveCoupon
     * @request POST:/subscriptions/{organizationId}/remove-coupon
     * @secure
     * @response `201` `SendInvoiceDto`
     */
    removeCoupon: (organizationId: string, params: RequestParams = {}) =>
      this.request<SendInvoiceDto, any>({
        path: `/subscriptions/${organizationId}/remove-coupon`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscriptions
     * @name ChangePaymentMethod
     * @request POST:/subscriptions/{organizationId}/change-payment-method
     * @secure
     * @response `201` `SendInvoiceDto`
     */
    changePaymentMethod: (organizationId: string, data: ChangePaymentMethodDto, params: RequestParams = {}) =>
      this.request<SendInvoiceDto, any>({
        path: `/subscriptions/${organizationId}/change-payment-method`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
 * No description
 *
 * @tags Subscriptions
 * @name GetStripeCustomerPortal
 * @request GET:/subscriptions/{organizationId}/customer-portal
 * @secure
 * @response `200` `{
    url: string,

}`
 */
    getStripeCustomerPortal: (organizationId: string, params: RequestParams = {}) =>
      this.request<
        {
          url: string;
        },
        any
      >({
        path: `/subscriptions/${organizationId}/customer-portal`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  subscriptiontiers = {
    /**
     * No description
     *
     * @tags Subscription tiers
     * @name FindAll
     * @request GET:/subscriptiontiers
     * @secure
     * @response `200` `(SubscriptionTier)[]`
     */
    findAll: (params: RequestParams = {}) =>
      this.request<SubscriptionTier[], any>({
        path: `/subscriptiontiers`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription tiers
     * @name Create
     * @request POST:/subscriptiontiers
     * @secure
     * @response `201` `SubscriptionTier`
     */
    create: (data: CreateSubscriptionTierDto, params: RequestParams = {}) =>
      this.request<SubscriptionTier, any>({
        path: `/subscriptiontiers`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription tiers
     * @name FindById
     * @request GET:/subscriptiontiers/{subscriptionTierId}
     * @secure
     * @response `200` `SubscriptionTier`
     */
    findById: (subscriptionTierId: string, params: RequestParams = {}) =>
      this.request<SubscriptionTier, any>({
        path: `/subscriptiontiers/${subscriptionTierId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription tiers
     * @name Update
     * @request PATCH:/subscriptiontiers/{subscriptionTierId}
     * @secure
     * @response `200` `SubscriptionTier`
     */
    update: (subscriptionTierId: string, data: UpdateSubscriptionTierDto, params: RequestParams = {}) =>
      this.request<SubscriptionTier, any>({
        path: `/subscriptiontiers/${subscriptionTierId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription tiers
     * @name Remove
     * @request DELETE:/subscriptiontiers/{subscriptionTierId}
     * @secure
     * @response `200` `void`
     */
    remove: (subscriptionTierId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/subscriptiontiers/${subscriptionTierId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription tiers
     * @name GetDefault
     * @request GET:/subscriptiontiers/default
     * @secure
     * @response `200` `SubscriptionTier`
     */
    getDefault: (
      query: {
        type: SubscriptionTierTypeEnum;
      },
      params: RequestParams = {},
    ) =>
      this.request<SubscriptionTier, any>({
        path: `/subscriptiontiers/default`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription tiers
     * @name SetDefault
     * @request POST:/subscriptiontiers/{subscriptionTierId}/set-default
     * @secure
     * @response `201` `SubscriptionTier`
     */
    setDefault: (
      subscriptionTierId: string,
      query: {
        type: SubscriptionTierTypeEnum;
      },
      params: RequestParams = {},
    ) =>
      this.request<SubscriptionTier, any>({
        path: `/subscriptiontiers/${subscriptionTierId}/set-default`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription tiers
     * @name CreatePrice
     * @request POST:/subscriptiontiers/{subscriptionTierId}/prices
     * @secure
     * @response `201` `SubscriptionTierPrice`
     */
    createPrice: (subscriptionTierId: string, data: CreatePriceDto, params: RequestParams = {}) =>
      this.request<SubscriptionTierPrice, any>({
        path: `/subscriptiontiers/${subscriptionTierId}/prices`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription tiers
     * @name SetDefaultPrice
     * @request POST:/subscriptiontiers/{subscriptionTierId}/prices/{priceId}/set-default
     * @secure
     * @response `201` `SubscriptionTierPrice`
     */
    setDefaultPrice: (subscriptionTierId: string, priceId: string, params: RequestParams = {}) =>
      this.request<SubscriptionTierPrice, any>({
        path: `/subscriptiontiers/${subscriptionTierId}/prices/${priceId}/set-default`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  coupons = {
    /**
     * No description
     *
     * @tags Coupons
     * @name FindAll
     * @request GET:/coupons
     * @secure
     * @response `200` `void`
     */
    findAll: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/coupons`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Coupons
     * @name CreateCoupon
     * @request POST:/coupons
     * @secure
     * @response `201` `SendInvoiceDto`
     */
    createCoupon: (data: CreateCouponDto, params: RequestParams = {}) =>
      this.request<SendInvoiceDto, any>({
        path: `/coupons`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Coupons
     * @name FindById
     * @request GET:/coupons/{couponId}
     * @secure
     * @response `200` `void`
     */
    findById: (couponId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/coupons/${couponId}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Coupons
     * @name UpdateCoupon
     * @request PATCH:/coupons/{couponId}
     * @secure
     * @response `200` `void`
     */
    updateCoupon: (couponId: string, data: UpdateCouponDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/coupons/${couponId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Coupons
     * @name FindAllCouponCodes
     * @request GET:/coupons/couponcodes
     * @secure
     * @response `200` `void`
     */
    findAllCouponCodes: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/coupons/couponcodes`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Coupons
     * @name FindCouponCodeById
     * @request GET:/coupons/couponcodes/{couponCodeId}
     * @secure
     * @response `200` `void`
     */
    findCouponCodeById: (couponCodeId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/coupons/couponcodes/${couponCodeId}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Coupons
     * @name UpdateCouponCode
     * @request PATCH:/coupons/couponcodes/{couponCodeId}
     * @secure
     * @response `200` `void`
     */
    updateCouponCode: (couponCodeId: string, data: UpdateCouponCodeDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/coupons/couponcodes/${couponCodeId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Coupons
     * @name CreateCouponCode
     * @request POST:/coupons/{couponId}/couponcodes
     * @secure
     * @response `201` `void`
     */
    createCouponCode: (couponId: string, data: CreateCouponCodeDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/coupons/${couponId}/couponcodes`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  organizations = {
    /**
     * No description
     *
     * @tags Organizations
     * @name Create
     * @request POST:/organizations
     * @secure
     * @response `201` `void`
     */
    create: (data: CreateOrganizationDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/organizations`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organizations
     * @name FindAll
     * @request GET:/organizations
     * @secure
     * @response `200` `void`
     */
    findAll: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/organizations`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organizations
     * @name Exists
     * @request GET:/organizations/exists
     * @secure
     * @response `200` `boolean`
     */
    exists: (
      query?: {
        orgnumber?: string;
        handle?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/organizations/exists`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organizations
     * @name FindOne
     * @request GET:/organizations/{id}
     * @secure
     * @response `200` `Organization`
     */
    findOne: (id: string, params: RequestParams = {}) =>
      this.request<Organization, any>({
        path: `/organizations/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organizations
     * @name Update
     * @request PATCH:/organizations/{id}
     * @secure
     * @response `200` `void`
     */
    update: (id: string, data: UpdateOrganizationDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/organizations/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organizations
     * @name Remove
     * @request DELETE:/organizations/{id}
     * @secure
     * @response `200` `void`
     */
    remove: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/organizations/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organizations
     * @name GetEhfStatus
     * @request GET:/organizations/{id}/ehfstatus
     * @secure
     * @response `200` `boolean`
     */
    getEhfStatus: (id: string, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/organizations/${id}/ehfstatus`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organizations
     * @name GetRates
     * @request GET:/organizations/{id}/rates
     * @secure
     * @response `200` `SendInvoiceDto`
     */
    getRates: (
      id: string,
      query: {
        type: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SendInvoiceDto, any>({
        path: `/organizations/${id}/rates`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organizations
     * @name UpdateRate
     * @request PATCH:/organizations/{id}/rates/{rateId}
     * @secure
     * @response `200` `void`
     */
    updateRate: (id: string, rateId: string, data: UpdateRateDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/organizations/${id}/rates/${rateId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  categories = {
    /**
     * No description
     *
     * @tags Categories
     * @name Create
     * @request POST:/categories
     * @secure
     * @response `201` `void`
     */
    create: (data: CreateCategoryDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/categories`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Categories
     * @name FindAll
     * @request GET:/categories
     * @secure
     * @response `200` `(Category)[]`
     */
    findAll: (
      query: {
        organizationId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<Category[], any>({
        path: `/categories`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Categories
     * @name FindOne
     * @request GET:/categories/{id}
     * @secure
     * @response `200` `Category`
     */
    findOne: (id: string, params: RequestParams = {}) =>
      this.request<Category, any>({
        path: `/categories/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Categories
     * @name Update
     * @request PATCH:/categories/{id}
     * @secure
     * @response `200` `void`
     */
    update: (id: string, data: UpdateCategoryDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/categories/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Categories
     * @name Remove
     * @request DELETE:/categories/{id}
     * @secure
     * @response `200` `void`
     */
    remove: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/categories/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  users = {
    /**
     * No description
     *
     * @tags Users
     * @name Create
     * @request POST:/users
     * @secure
     * @response `201` `User`
     */
    create: (data: CreateUserDto, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/users`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name FindAll
     * @request GET:/users
     * @secure
     * @response `200` `(User)[]`
     */
    findAll: (params: RequestParams = {}) =>
      this.request<User[], any>({
        path: `/users`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name FindCurrent
     * @request GET:/users/current
     * @secure
     * @response `200` `User`
     */
    findCurrent: (params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/users/current`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name GetInboxAvailability
     * @request GET:/users/inbox-availability
     * @secure
     * @response `200` `void`
     */
    getInboxAvailability: (
      query: {
        email: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/users/inbox-availability`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name GetInbox
     * @request GET:/users/inbox
     * @secure
     * @response `200` `(InboxAttachment)[]`
     */
    getInbox: (params: RequestParams = {}) =>
      this.request<InboxAttachment[], any>({
        path: `/users/inbox`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UpsertInbox
     * @request POST:/users/inbox
     * @secure
     * @response `201` `void`
     */
    upsertInbox: (data: UpsertInboxDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/inbox`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name DeleteInbox
     * @request DELETE:/users/inbox/{inboxId}
     * @secure
     * @response `200` `string`
     */
    deleteInbox: (inboxId: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/users/inbox/${inboxId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name DeleteInboxAttachment
     * @request DELETE:/users/inbox/{inboxId}/attachments/{attachmentId}
     * @secure
     * @response `200` `string`
     */
    deleteInboxAttachment: (inboxId: string, attachmentId: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/users/inbox/${inboxId}/attachments/${attachmentId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name FindOne
     * @request GET:/users/{id}
     * @secure
     * @response `200` `void`
     */
    findOne: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/${id}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name Update
     * @request PATCH:/users/{id}
     * @secure
     * @response `200` `void`
     */
    update: (id: string, data: UpdateUserDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name Remove
     * @request DELETE:/users/{id}
     * @secure
     * @response `200` `void`
     */
    remove: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name AddPaymentCard
     * @request POST:/users/{id}/paymentcard
     * @secure
     * @response `201` `void`
     */
    addPaymentCard: (id: string, data: AddPaymentCardDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/${id}/paymentcard`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UpdatePaymentCard
     * @request PATCH:/users/{id}/paymentcard/{paymentCardId}
     * @secure
     * @response `200` `void`
     */
    updatePaymentCard: (id: string, paymentCardId: string, data: UpdatePaymentCardDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/${id}/paymentcard/${paymentCardId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name RemovePaymentCard
     * @request DELETE:/users/{id}/paymentcard/{paymentCardId}
     * @secure
     * @response `200` `void`
     */
    removePaymentCard: (id: string, paymentCardId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/${id}/paymentcard/${paymentCardId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name AddPushToken
     * @request POST:/users/{id}/push-token
     * @secure
     * @response `201` `void`
     */
    addPushToken: (id: string, data: AddPushTokenDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/${id}/push-token`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name RemovePushToken
     * @request DELETE:/users/{id}/push-token/{pushToken}
     * @secure
     * @response `200` `void`
     */
    removePushToken: (id: string, pushToken: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/${id}/push-token/${pushToken}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name ParseEmailAttachments
     * @request POST:/users/inbox/parse
     * @secure
     * @response `201` `string`
     */
    parseEmailAttachments: (data: AddInboxAttachmentDto, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/users/inbox/parse`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),
  };
  auth = {
    /**
     * @description Register a new user.
     *
     * @tags Auth
     * @name Register
     * @request POST:/auth/register
     * @response `201` `TokensDto`
     */
    register: (data: RegisterDto, params: RequestParams = {}) =>
      this.request<TokensDto, any>({
        path: `/auth/register`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Log in a user.
     *
     * @tags Auth
     * @name Login
     * @request POST:/auth/login
     * @response `201` `TokensDto`
     */
    login: (data: LoginDto, params: RequestParams = {}) =>
      this.request<TokensDto, any>({
        path: `/auth/login`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Redirect to the Vipps authorization page.
     *
     * @tags Auth
     * @name Vipps
     * @request GET:/auth/vipps
     * @response `200` `void`
     */
    vipps: (
      query: {
        callback_uri: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/auth/vipps`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * @description Handle the callback from Vipps.
     *
     * @tags Auth
     * @name VippsCallback
     * @request GET:/auth/vipps/callback
     * @response `200` `void`
     */
    vippsCallback: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/vipps/callback`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name Logout
     * @request POST:/auth/logout
     * @secure
     * @response `201` `void`
     */
    logout: (data: LogoutDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/logout`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Refresh an access token.
     *
     * @tags Auth
     * @name Refresh
     * @request POST:/auth/refresh
     * @response `201` `TokensDto`
     */
    refresh: (
      query: {
        singlePageApplication: string;
      },
      data: RefreshDto,
      params: RequestParams = {},
    ) =>
      this.request<TokensDto, any>({
        path: `/auth/refresh`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Reset a user's password.
     *
     * @tags Auth
     * @name ResetPassword
     * @request POST:/auth/reset-password
     * @response `201` `void`
     */
    resetPassword: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/reset-password`,
        method: "POST",
        ...params,
      }),

    /**
     * @description Send a password reset email.
     *
     * @tags Auth
     * @name ForgotPassword
     * @request POST:/auth/forgot-password
     * @response `201` `void`
     */
    forgotPassword: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/forgot-password`,
        method: "POST",
        ...params,
      }),

    /**
     * @description Login with test user
     *
     * @tags Auth
     * @name Test
     * @request POST:/auth/test-login
     * @response `201` `TokensDto`
     */
    test: (data: TestUserLoginDto, params: RequestParams = {}) =>
      this.request<TokensDto, any>({
        path: `/auth/test-login`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  departments = {
    /**
     * No description
     *
     * @tags Departments
     * @name Create
     * @request POST:/departments
     * @secure
     * @response `201` `void`
     */
    create: (data: CreateDepartmentDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/departments`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Departments
     * @name FindAll
     * @request GET:/departments
     * @secure
     * @response `200` `(object)[]`
     */
    findAll: (
      query: {
        organizationId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<object[], any>({
        path: `/departments`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Departments
     * @name FindOne
     * @request GET:/departments/{id}
     * @secure
     * @response `200` `SendInvoiceDto`
     */
    findOne: (id: string, params: RequestParams = {}) =>
      this.request<SendInvoiceDto, any>({
        path: `/departments/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Departments
     * @name Update
     * @request PATCH:/departments/{id}
     * @secure
     * @response `200` `void`
     */
    update: (id: string, data: UpdateDepartmentDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/departments/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Departments
     * @name Remove
     * @request DELETE:/departments/{id}
     * @secure
     * @response `200` `void`
     */
    remove: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/departments/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Departments
     * @name ConnectAccounts
     * @request POST:/departments/{id}/accounts/connect
     * @secure
     * @response `201` `void`
     */
    connectAccounts: (id: string, data: ConnectOrDisconnectAccountsDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/departments/${id}/accounts/connect`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Departments
     * @name DisconnectAccounts
     * @request POST:/departments/{id}/accounts/disconnect
     * @secure
     * @response `201` `void`
     */
    disconnectAccounts: (id: string, data: ConnectOrDisconnectAccountsDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/departments/${id}/accounts/disconnect`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Departments
     * @name AddProjects
     * @request POST:/departments/{id}/projects/add
     * @secure
     * @response `201` `void`
     */
    addProjects: (id: string, data: string[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/departments/${id}/projects/add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Departments
     * @name RemoveProject
     * @request POST:/departments/{id}/projects/remove
     * @secure
     * @response `201` `void`
     */
    removeProject: (id: string, data: RemoveProjectsDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/departments/${id}/projects/remove`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  reports = {
    /**
     * No description
     *
     * @tags Reports
     * @name Create
     * @request POST:/reports
     * @secure
     * @response `201` `Report`
     */
    create: (data: CreateReportDto, params: RequestParams = {}) =>
      this.request<Report, any>({
        path: `/reports`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name FindAllWhere
     * @request GET:/reports
     * @secure
     * @response `200` `(Report)[]`
     */
    findAllWhere: (
      query?: {
        organizationId?: string;
        accountId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<Report[], any>({
        path: `/reports`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name Update
     * @request PATCH:/reports/{id}
     * @secure
     * @response `200` `Report`
     */
    update: (id: string, data: UpdateReportDto, params: RequestParams = {}) =>
      this.request<Report, any>({
        path: `/reports/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name Remove
     * @request DELETE:/reports/{id}
     * @secure
     * @response `200` `void`
     */
    remove: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/reports/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name FindOne
     * @request GET:/reports/{id}
     * @secure
     * @response `200` `Report`
     */
    findOne: (id: string, params: RequestParams = {}) =>
      this.request<Report, any>({
        path: `/reports/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name Submit
     * @request POST:/reports/{id}/submit
     * @secure
     * @response `201` `Report`
     */
    submit: (id: string, params: RequestParams = {}) =>
      this.request<Report, any>({
        path: `/reports/${id}/submit`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name Approve
     * @request POST:/reports/{id}/approve
     * @secure
     * @response `201` `Report`
     */
    approve: (id: string, params: RequestParams = {}) =>
      this.request<Report, any>({
        path: `/reports/${id}/approve`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reports
     * @name Reject
     * @request POST:/reports/{id}/reject
     * @secure
     * @response `201` `Report`
     */
    reject: (id: string, params: RequestParams = {}) =>
      this.request<Report, any>({
        path: `/reports/${id}/reject`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  invoices = {
    /**
     * No description
     *
     * @tags Invoices
     * @name Create
     * @request POST:/invoices
     * @secure
     * @response `201` `Invoice`
     */
    create: (data: CreateInvoiceDto, params: RequestParams = {}) =>
      this.request<Invoice, any>({
        path: `/invoices`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoices
     * @name Send
     * @request POST:/invoices/{id}/send
     * @secure
     * @response `201` `void`
     */
    send: (id: string, data: SendInvoiceDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/invoices/${id}/send`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  expenses = {
    /**
     * No description
     *
     * @tags Expenses
     * @name Upsert
     * @request POST:/expenses
     * @secure
     * @response `201` `void`
     */
    upsert: (data: UpsertExpenseDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/expenses`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expenses
     * @name FindAllByQuery
     * @request GET:/expenses
     * @secure
     * @response `200` `(object)[]`
     */
    findAllByQuery: (
      query?: {
        reportId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<object[], any>({
        path: `/expenses`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expenses
     * @name Update
     * @request PATCH:/expenses/{id}
     * @secure
     * @response `200` `void`
     */
    update: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/expenses/${id}`,
        method: "PATCH",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expenses
     * @name DeleteExpense
     * @request DELETE:/expenses/{id}
     * @secure
     * @response `200` `void`
     */
    deleteExpense: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/expenses/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expenses
     * @name Reject
     * @request POST:/expenses/{id}/reject
     * @secure
     * @response `201` `void`
     */
    reject: (id: string, data: ReviewExpenseDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/expenses/${id}/reject`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expenses
     * @name FindOne
     * @request GET:/expenses/id
     * @secure
     * @response `200` `SendInvoiceDto`
     */
    findOne: (id: string, params: RequestParams = {}) =>
      this.request<SendInvoiceDto, any>({
        path: `/expenses/id`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expenses
     * @name UpsertDiet
     * @request PATCH:/expenses/{id}/diets/{dietId}
     * @secure
     * @response `200` `void`
     */
    upsertDiet: (id: string, dietId: string, data: UpsertDietDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/expenses/${id}/diets/${dietId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expenses
     * @name FindAllAttachmentsByExpenseId
     * @request GET:/expenses/{id}/attachments
     * @secure
     * @response `200` `(AttachmentResponseDto)[]`
     */
    findAllAttachmentsByExpenseId: (id: string, params: RequestParams = {}) =>
      this.request<AttachmentResponseDto[], any>({
        path: `/expenses/${id}/attachments`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expenses
     * @name UploadAttachment
     * @request POST:/expenses/{id}/attachments
     * @secure
     * @response `201` `void`
     */
    uploadAttachment: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/expenses/${id}/attachments`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expenses
     * @name DeleteAttachment
     * @request DELETE:/expenses/attachments/{attachmentId}
     * @secure
     * @response `200` `void`
     */
    deleteAttachment: (attachmentId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/expenses/attachments/${attachmentId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expenses
     * @name OcrFile
     * @request POST:/expenses/ocr
     * @secure
     * @response `201` `OcrReponseDto`
     */
    ocrFile: (params: RequestParams = {}) =>
      this.request<OcrReponseDto, any>({
        path: `/expenses/ocr`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Expenses
     * @name GetExchangeRate
     * @request POST:/expenses/exchangerate
     * @secure
     * @response `201` `number`
     */
    getExchangeRate: (data: ExchangeRateDto, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/expenses/exchangerate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  invitations = {
    /**
     * No description
     *
     * @tags Invitations
     * @name GetInvitationsByUser
     * @request GET:/invitations
     * @secure
     * @response `200` `(Invitation)[]`
     */
    getInvitationsByUser: (params: RequestParams = {}) =>
      this.request<Invitation[], any>({
        path: `/invitations`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invitations
     * @name GetInvitationByToken
     * @request POST:/invitations
     * @secure
     * @response `201` `Invitation`
     */
    getInvitationByToken: (data: GetInviteTokenDto, params: RequestParams = {}) =>
      this.request<Invitation, any>({
        path: `/invitations`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invitations
     * @name InviteByEmail
     * @request POST:/invitations/email
     * @secure
     * @response `201` `void`
     */
    inviteByEmail: (data: InviteByEmailDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/invitations/email`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invitations
     * @name InviteByPhone
     * @request POST:/invitations/phone
     * @secure
     * @response `201` `void`
     */
    inviteByPhone: (data: InviteByPhoneDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/invitations/phone`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
 * No description
 *
 * @tags Invitations
 * @name InviteByLink
 * @request POST:/invitations/link
 * @secure
 * @response `200` `{
    url: string,

}`
 * @response `201` `void`
 */
    inviteByLink: (data: InviteByLinkDto, params: RequestParams = {}) =>
      this.request<
        {
          url: string;
        },
        any
      >({
        path: `/invitations/link`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invitations
     * @name RevokeInviteByToken
     * @request POST:/invitations/revoke
     * @secure
     * @response `201` `string`
     */
    revokeInviteByToken: (data: RevokeInviteTokenDto, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/invitations/revoke`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invitations
     * @name AcceptInviteByToken
     * @request POST:/invitations/accept
     * @secure
     * @response `201` `void`
     */
    acceptInviteByToken: (data: AcceptInviteTokenDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/invitations/accept`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  metrics = {
    /**
     * No description
     *
     * @tags Organizations
     * @name Index
     * @request GET:/metrics
     * @secure
     * @response `200` `string`
     */
    index: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/metrics`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
