import { Invoice } from '__generated__/UtleggsappenApi';
import { useReports } from 'api/reports/useReports';
import { LargeButton } from 'components/buttons/LargeButton';
import Input from 'components/input-field/Input';
import { toast } from 'components/toast/Toast';
import { useStoredOrg } from 'context/OrganizationContext';
import { useTheme } from 'context/theme';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { invoiceService } from 'services/invoiceService';
import { cn } from 'utils/className';

interface InvoiceFormValues {
  invoiceNumber: string;
}

interface ResendFormValues {
  invoiceNumber: string;
  sendAs: string;
}

export default function InternalTools() {
  const theme = useTheme();

  const { data: reports } = useReports();
  const { organization } = useStoredOrg();

  const [submitting, setSubmitting] = useState<boolean | string>(false);

  function handleInvoiceNumberChange(
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: string) => void,
  ) {
    const value = e.currentTarget.value.toString().replace(/ /g, '');
    if (value.length === 0) setFieldValue('invoiceNumber', '');
    if (!/^[0-9 ]+$/.test(value)) return;
    setFieldValue('invoiceNumber', value);
  }

  async function generateInvoice({ invoiceNumber }: InvoiceFormValues) {
    setSubmitting('newInvoice');
    const report = reports?.find((report) =>
      report.invoices!.find((invoice) => invoice.invoiceNumber === parseInt(invoiceNumber)),
    );
    if (!report) {
      setSubmitting(false);
      return toast.error({ description: 'Faktura ikke funnet' });
    }
    invoiceService
      .create({ reportId: report.id })
      .then(() => {
        setSubmitting(false);
        toast.success({ description: 'Faktura generert' });
      })
      .catch((error) => {
        setSubmitting(false);
        toast.error({ description: `Noe gikk galt: ${error}` });
      });
  }

  async function resendInvoice({ invoiceNumber, sendAs }: ResendFormValues) {
    let invoice: Invoice | undefined = undefined;

    for (const report of reports ?? []) {
      invoice = report.invoices!.find((invoice) => invoice.invoiceNumber === parseInt(invoiceNumber));
      if (invoice) break;
    }

    if (invoice === undefined) {
      return toast.error({ description: 'Faktura ikke funnet' });
    }

    setSubmitting(`resendInvoice${sendAs}`);
    invoiceService
      .send(invoice.id, {
        email: sendAs === 'PDF',
        ehf: sendAs === 'EHF',
      })
      .then((res) => {
        console.log(res);
        setSubmitting(false);
        toast.success({ description: `Faktura sendt som ${sendAs}` });
      })
      .catch((error) => {
        setSubmitting(false);
        if (error === 'Not Found') {
          return toast.error({ description: 'Faktura ikke funnet' });
        } else {
          toast.error({ description: `Noe gikk galt: ${error}` });
        }
      });
  }

  return (
    <div className="flex h-full items-center justify-center" style={{ backgroundColor: theme.background.accent }}>
      <div className="mb-8 flex flex-col items-center space-y-6">
        <h1 className="bigheading text-center" style={{ color: theme.label.primary }}>
          Internverktøy
        </h1>
        <div className={cn('rounded-large bg-white p-5')}>
          <Formik onSubmit={generateInvoice} initialValues={{ invoiceNumber: '' }} validateOnChange={false}>
            {({ values, errors, touched, setFieldValue, handleBlur }) => {
              return (
                <Form className="space-y-5">
                  <Input.Text
                    label="Fakturanummer"
                    width="lg"
                    value={values.invoiceNumber}
                    error={touched.invoiceNumber ? errors.invoiceNumber : undefined}
                    onChange={(e) => handleInvoiceNumberChange(e, setFieldValue)}
                    onBlur={handleBlur('invoiceNumber')}
                  />
                  <div className="mt-6 flex h-12 w-full justify-center">
                    <LargeButton
                      backgroundColor="light"
                      defaultStyle="indigo"
                      disabled={false}
                      loading={submitting === 'newInvoice'}
                      type="submit">
                      Generer ny faktura
                    </LargeButton>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        <div className={cn('rounded-large bg-white p-5')}>
          <Formik onSubmit={resendInvoice} initialValues={{ invoiceNumber: '', sendAs: '' }} validateOnChange={false}>
            {({ values, errors, touched, setFieldValue, handleBlur }) => {
              return (
                <Form className="space-y-5">
                  <Input.Text
                    label="Fakturanummer"
                    width="lg"
                    value={values.invoiceNumber}
                    error={touched.invoiceNumber ? errors.invoiceNumber : undefined}
                    onChange={(e) => handleInvoiceNumberChange(e, setFieldValue)}
                    onBlur={handleBlur('invoiceNumber')}
                  />
                  <div className="mt-6 flex h-12 w-full justify-around gap-5">
                    <LargeButton
                      backgroundColor="light"
                      defaultStyle="indigo"
                      disabled={!organization.invoiceEmail}
                      loading={submitting === 'resendInvoicePDF'}
                      onClick={() => setFieldValue('sendAs', 'PDF')}
                      type="submit">
                      Send PDF
                    </LargeButton>
                    <LargeButton
                      backgroundColor="light"
                      defaultStyle="indigo"
                      disabled={!organization.invoiceAsEhf}
                      loading={submitting === 'resendInvoiceEHF'}
                      onClick={() => setFieldValue('sendAs', 'EHF')}
                      type="submit">
                      Send EHF
                    </LargeButton>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
}
