import { useOrganizations } from 'api/organizations/useOrganizations';
import { Arrow } from 'assets/svgcomponents/Arrow';
import { BannerContainer } from 'components/banner/Banner';
import { LargeButton } from 'components/buttons/LargeButton';
import { SmallButton } from 'components/buttons/SmallButton';
import { useTheme } from 'context/theme';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { authService } from 'services/authService';
import { useIsMobile } from 'utils/useIsMobile';
import Navbar from './Navbar';

function NoOrganizations() {
  return (
    <div className="mt-8 flex flex-col items-center justify-center">
      <div className="text-2xl font-bold">Ingen organisasjoner</div>
      <div className="text-lg">Du har ikke tilgang til dashbord i noen organisasjoner.</div>
      <div className="pb-6 text-lg">Opprett en ny organisasjon, eller be en administrator legge deg til.</div>
      <LargeButton backgroundColor="light" defaultStyle="indigo" href="registrer">
        Opprett ny
      </LargeButton>
    </div>
  );
}

function ChooseOrganization() {
  const navigate = useNavigate();
  const { data: organizations } = useOrganizations();
  return (
    <div className="mt-8 flex flex-col items-center justify-center space-y-8">
      <div>
        <div className="text-2xl font-bold">Du er med i flere organisasjoner</div>
        <div className="text-center text-lg">Velg en organisasjon fra menyen</div>
      </div>
      <div className={'grid gap-5 ' + (organizations?.length ?? 0 > 5 ? 'grid-cols-2' : 'grid-cols-1')}>
        {organizations?.map((organization, i: number) => (
          <div
            key={i}
            onClick={() => navigate(`/${organization.handle}`)}
            className="group flex w-96 cursor-pointer justify-between rounded-xl border border-gray-200 bg-white p-6 shadow-sm hover:shadow-lg">
            <div>{organization.displayName}</div>
            <div className="rotate-180 transition-all group-hover:translate-x-3">
              <Arrow className="h-6 w-6" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function DashboardProvider({ children }: { children: JSX.Element }) {
  const { data: organizations } = useOrganizations();
  const { pathname } = useLocation();

  if (pathname === '/' && organizations?.length === 0) {
    return <NoOrganizations />;
  }

  if (pathname === '/' && organizations?.length === 1) {
    return <Navigate to={'/' + organizations[0].handle} replace />;
  }

  if (pathname === '/' && (organizations?.length ?? 0) > 0) {
    return <ChooseOrganization />;
  }

  return children;
}

export default function Dashboard(props: any) {
  const theme = useTheme();
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <div className="flex h-screen w-screen flex-col items-center justify-center px-6 pb-20 pt-10">
        <div className="flex flex-col items-center space-y-2">
          <h1 className="smallnumbers text-[30px]">
            <a href="https://www.utleggsappen.no">UTLEGGSAPPEN</a>
          </h1>
          <p className="smalltextbold text-center">Dashbord er ikke støttet på mobil</p>
          <p className="smalltext pt-2 text-center">Åpne denne siden på en pc, eller </p>
          <SmallButton
            backgroundColor="light"
            defaultStyle="indigo"
            onClick={() => window.open('https://utleggsappen.no/last-ned', '_blank', 'noopener,noreferrer')}>
            Gå til appen
          </SmallButton>
        </div>
        <div className="absolute bottom-20">
          <SmallButton backgroundColor="light" defaultStyle="indigo" onClick={() => authService.logout()}>
            Logg ut
          </SmallButton>
        </div>
      </div>
    );
  }

  return (
    <div
      className="min-w-screen max-w-screen flex min-h-screen justify-center"
      style={{ backgroundColor: theme.background.accent, color: theme.label.primary }}>
      <div className="relative flex w-full" id="dashboard">
        <Navbar />
        <BannerContainer />

        <div className="relative flex w-full flex-col overflow-x-hidden" id="dashboard-root">
          <div className="relative flex min-h-full max-w-full flex-col gap-y-5 overflow-x-hidden p-10">
            <DashboardProvider>
              <Outlet />
            </DashboardProvider>
          </div>
        </div>
      </div>
    </div>
  );
}
