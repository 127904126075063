import { Organization } from '__generated__/UtleggsappenApi';
import { fetcher } from 'api';
import { useStoredUser } from 'context/UserContext';
import useSWR from 'swr';

export function useOrganizations() {
  const { user } = useStoredUser();

  const { data, error, mutate, isLoading } = useSWR<Organization[]>(user.role < 2 ? '/organizations' : null, fetcher);

  const organizations = user?.accounts?.filter((account) => account.role < 4).map((account) => account.organization);

  return {
    data: data! ?? organizations,
    error,
    mutate,
    isLoading,
  };
}
