import { Hourglass } from '@phosphor-icons/react';
import { SubscriptionPaymentMethodType, SubscriptionStatusTypeEnum } from '__generated__/UtleggsappenApi';
import { useAccount } from 'api/accounts/useAccount';
import { api } from 'api/api';
import { useOrganization } from 'api/organizations/useOrganization';
import { useSubscription } from 'api/subscriptions/useSubscription';
import { useUser } from 'api/users/useUser';
import { CircleCheck } from 'assets/svgcomponents/CircleCheck';
import { CircleExclamation } from 'assets/svgcomponents/CircleExclamation';
import { CircleInfo } from 'assets/svgcomponents/CircleInfo';
import { Close } from 'assets/svgcomponents/Close';
import { TailwindIndicator } from 'components/TailwindMediaIndicator';
import { alert } from 'components/alerts/Alert';
import { AlertInstance } from 'components/alerts/AlertInstance';
import { banner, hideBanner } from 'components/banner/Banner';
import { LiveChatButton } from 'components/buttons/LiveChatButton';
import { TextButton } from 'components/buttons/TextButton';
import { AccessContext, useAccess } from 'context/AccessContext';
import { OrgContext } from 'context/OrganizationContext';
import { UserContext, useStoredUser } from 'context/UserContext';
import { useEffect, useRef } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useIntercom } from 'react-use-intercom';
import Home from 'screens/Home';
import InternalTools from 'screens/InternalTools';
import Login from 'screens/Login';
import LoginCallback from 'screens/LoginCallback';
import PageNotFound from 'screens/PageNotFound';
import { Welcome } from 'screens/Welcome';
import Archive from 'screens/archive/Archive';
import Departments from 'screens/departments/Departments';
import { NewDepartment } from 'screens/departments/NewDepartment';
import EditDepartment from 'screens/departments/edit/EditDepartment';
import Dsm from 'screens/dsm';
import ListExpenses from 'screens/expenses/ListExpenses';
import { ApproveExpense } from 'screens/expenses/approve/ApproveExpense';
import InviteJoinLogin from 'screens/invitejoin/InviteJoinLogin';
import { ProfileSettings } from 'screens/profile/ProfileSettings';
import { Settings } from 'screens/settings/AllSettings';
import BusinessSettings from 'screens/settings/BusinessSettings';
import CustomizationSettings from 'screens/settings/CustomizationSettings';
import DietTypeSettings from 'screens/settings/Diets/DietTypeSettings';
import EditDietType from 'screens/settings/Diets/EditDietType';
import DriveTypeSettings from 'screens/settings/Drive/DriveTypeSettings';
import EditDriveMapping from 'screens/settings/Drive/EditDriveMapping';
import EditDriveRate from 'screens/settings/Drive/EditDriveRate';
import EditExpenseType from 'screens/settings/ExpenseTypes/EditExpenseType';
import ExpenseTypeSettings from 'screens/settings/ExpenseTypes/ExpenseTypeSettings';
import NewType from 'screens/settings/ExpenseTypes/NewType';
import Subscription from 'screens/settings/subscription';
import Checkout from 'screens/settings/subscription/Checkout';
import SelectTier from 'screens/settings/subscription/SelectTier';
import Success from 'screens/settings/subscription/Success';
import UpcomingInvoice from 'screens/settings/subscription/UpcomingInvoice';
import Signup from 'screens/signup/Signup';
import SignupOrganization from 'screens/signup/SignupOrganization';
import { EditUser } from 'screens/users/EditUser';
import Users from 'screens/users/Users';
import Dashboard from './components/layout/Dashboard';

interface ProtectedRouteProps {
  redirectPath?: string;
  dashboard?: boolean;
}

const ProtectedRouteUser = ({ redirectPath = '/login', dashboard = true }: ProtectedRouteProps) => {
  const { data: user, isLoading, loggedOut, mutate } = useUser();
  const { update, isOpen } = useIntercom();

  useEffect(() => {
    if (user && isOpen) {
      update({
        name: `${user.givenName} ${user.familyName}`,
        email: user.email,
        userId: user.id,
        userHash: user.intercomWebHash,
      });
    }
  }, [user, isOpen]);

  if (isLoading) return <></>;
  if (loggedOut) return <Navigate to={redirectPath} replace />;
  if (!user) return <Navigate to={redirectPath} replace />;

  if (dashboard) {
    return (
      <UserContext.Provider value={{ user, setUser: mutate }}>
        <Dashboard>
          <Outlet />
        </Dashboard>
      </UserContext.Provider>
    );
  } else {
    return (
      <UserContext.Provider value={{ user, setUser: mutate }}>
        <Outlet />
      </UserContext.Provider>
    );
  }
};

const ProtectedRouteOrg = ({ redirectPath = '/' }: ProtectedRouteProps) => {
  const { data: org, isLoading, mutate } = useOrganization();
  const { data: subscription, isLoading: isLoadingSubscription } = useSubscription();
  const { user } = useStoredUser();
  const { data: account } = useAccount();

  useEffect(() => {
    const isTrialing = org?.subscription?.subscriptionStatus === SubscriptionStatusTypeEnum.Trialing;
    const customerHasPaymentMethod = org?.subscription?.paymentMethod !== SubscriptionPaymentMethodType.NONE;

    if (org && subscription && isTrialing && !customerHasPaymentMethod) {
      banner({
        id: 'trial-banner',
        content: (
          <div className="centered space-x-2">
            <Hourglass size={20} />
            <p>
              {Math.floor(
                (new Date(org.subscription!.billingPeriodEnd).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24),
              )}{' '}
              dager igjen av prøveperioden.
            </p>
            <TextButton
              backgroundColor="light"
              className="font-bold underline"
              onClick={async () => {
                await api.subscriptions.getStripeCustomerPortal(org!.id).then((res) => {
                  window.location.href = res.data.url;
                });
              }}>
              Oppgrader nå
            </TextButton>
          </div>
        ),
      });
    } else {
      hideBanner('trial-banner');
    }
  }, [org, subscription]);

  if (isLoading) return <></>;
  if (!org) return <Navigate to={redirectPath} replace />;
  if (user.role > 2 && (account?.role ?? 4) > 2) return <Navigate to={redirectPath} replace />;

  return (
    <OrgContext.Provider value={{ organization: org, setOrganization: mutate }}>
      <AccessContext.Provider
        value={{
          userRole: user.role,
          accountRole: account?.role ?? 4,
          combinedRole: Math.min(user.role, account?.role ?? 4),
        }}>
        <Outlet />
      </AccessContext.Provider>
    </OrgContext.Provider>
  );
};

export const ProtectedRouteAdmin = ({ redirectPath = '/' }: ProtectedRouteProps) => {
  const { combinedRole } = useAccess();

  if (combinedRole === null || combinedRole > 1) return <Navigate to={redirectPath} replace />;

  return <Outlet />;
};

export const ProtectedRouteRootAdmin = ({ redirectPath = '/' }: ProtectedRouteProps) => {
  const { combinedRole } = useAccess();

  if (combinedRole === null || combinedRole > 0) return <Navigate to={redirectPath} replace />;

  return <Outlet />;
};

export default function App() {
  const alertRef = useRef<null>(null);
  useEffect(() => {
    alert.setRef(alertRef);
  }, []);

  return (
    <>
      <LiveChatButton />
      <ToastContainer
        hideProgressBar
        closeButton={<Close className="h-[24px] w-[24px]" />}
        transition={Slide}
        position="bottom-right"
        icon={({ type }) => {
          switch (type) {
            case 'success':
              return <CircleCheck />;
            case 'error':
              return <CircleExclamation />;
            case 'warning':
              return <CircleExclamation />;
            case 'info':
              return <CircleInfo />;
            default:
              return null;
          }
        }}
      />

      <AlertInstance ref={alertRef} />
      <Routes>
        <Route path="/registrer/*" element={<ProtectedRouteUser redirectPath="/registrer" dashboard={false} />}>
          <Route path="organisasjon" element={<SignupOrganization />} />
        </Route>

        <Route path=":handle/velkommen" element={<Welcome />} />
        <Route path="/" element={<ProtectedRouteUser />}>
          {/* Alle har tilgang til profilsiden, selv om de ikke har bruker */}
          <Route path="profil/*" element={<ProfileSettings />} />
          <Route path=":handle" element={<ProtectedRouteOrg />}>
            {/* Alle brukere (rolle < 3) har tilgang til ruter under */}
            <Route index element={<Home />} />
            <Route path="utlegg/*">
              <Route index element={<ListExpenses />} />
              <Route path=":reportId" element={<ApproveExpense />} />
            </Route>
            <Route path="arkiv" element={<Archive />} />
            <Route path="profil/*" element={<ProfileSettings />} />
            <Route path="internt" element={<ProtectedRouteRootAdmin />}>
              {/* Superadminer (rolle < 1) har tilgang til ruter under */}
              <Route index element={<InternalTools />} />
            </Route>
            <Route path="innstillinger/*" element={<ProtectedRouteAdmin />}>
              {/* Adminer (rolle < 2) har tilgang til ruter under */}
              <Route index element={<Settings />} />
              <Route path="organisasjon" element={<BusinessSettings />} />
              <Route path="abonnement/*">
                <Route index element={<Subscription />} />
                <Route path="endre" element={<SelectTier />} />
                <Route path="checkout" element={<Checkout />} />
                <Route path="suksess" element={<Success />} />
                <Route path="neste" element={<UpcomingInvoice />} />
              </Route>
              <Route path="administrer" element={<CustomizationSettings />} />
              <Route path="dietter">
                <Route index element={<DietTypeSettings />} />
                <Route path="*" element={<EditDietType />} />
              </Route>
              <Route path="utleggstyper">
                <Route index element={<ExpenseTypeSettings />} />
                <Route path="*" element={<EditExpenseType />} />
                <Route path="ny" element={<NewType />} />
              </Route>
              <Route path="kjoregodtgjorelse">
                <Route index element={<DriveTypeSettings />} />
                <Route path="mapping/*" element={<EditDriveMapping />} />
                <Route path="satser/*" element={<EditDriveRate />} />
              </Route>
            </Route>
            <Route path="avdelinger" element={<ProtectedRouteAdmin />}>
              <Route index element={<Departments />} />
              <Route path=":departmentId" element={<EditDepartment />} />
              <Route path="ny" element={<NewDepartment />} />
            </Route>
            <Route path="brukere" element={<ProtectedRouteAdmin />}>
              <Route index element={<Users />} />
              <Route path="rediger" element={<EditUser />} />
            </Route>
            <Route path="organisasjon" element={<ProtectedRouteAdmin />}>
              <Route index element={<BusinessSettings />} />
            </Route>
          </Route>
        </Route>
        <Route path="login/callback/*" element={<LoginCallback />} />
        <Route path="login" element={<Login />} />
        <Route path="registrer" element={<Signup />} />
        <Route path="invitasjon" element={<InviteJoinLogin />} />
        <Route path="404" element={<PageNotFound />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/dsm" element={<Dsm />} />
      </Routes>
      <TailwindIndicator />
    </>
  );
}
