import { useSubscription } from 'api/subscriptions/useSubscription';
import { Arrow } from 'assets/svgcomponents/Arrow';
import { TextButton } from 'components/buttons/TextButton';
import { FullscreenLoading } from 'components/layout/LoadingIndicator';
import { useTheme } from 'context/theme';
import { useNavigate } from 'react-router-dom';
import { cn } from 'utils/className';

export default function UpcomingInvoice() {
  const theme = useTheme();
  const { data: subscription, isLoading: isLoadingSubscription } = useSubscription();

  const navigate = useNavigate();

  if (isLoadingSubscription) {
    return <FullscreenLoading />;
  }

  return (
    <>
      <div className="flex flex-col justify-between pt-5">
        <TextButton
          backgroundColor="light"
          className="flex"
          onClick={() => navigate('..')}
          icon={<Arrow className="h-[21px]" />}
          iconPlacement="left">
          Tilbake
        </TextButton>
        <h1 className="bigheading" style={{ color: theme.label.primary }}>
          Forhåndsvisning
        </h1>
      </div>
      <p className="caption">
        Faktureres{' '}
        {new Date(Math.round(subscription?.stripeInvoices.upcoming?.created! * 1000)).toLocaleDateString('no-NO', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })}
      </p>
      <div
        className="w-fit rounded-large px-5 py-3"
        style={{
          backgroundColor: theme.background.primary,
        }}>
        <div className="w-fit">
          {subscription?.stripeInvoices.upcoming.lines.data.map((line, index) => {
            return (
              <div
                key={line.id}
                className={cn(
                  'flex justify-between space-x-10 py-3',
                  index !== subscription?.stripeInvoices.upcoming.lines.data.length - 1 &&
                    'border-b-2 border-v2-purple-50',
                )}>
                <p className="smalltext">{translateLineDescription(line.description!)}</p>
                <p className="smalltextbold">{line.amount / 100} kr</p>
              </div>
            );
          })}
          <div className="h-1 w-full rounded-full bg-v2-purple-200" />
          <div className="flex justify-between space-x-10 py-3">
            <p className="smalltext">Sluttsum</p>
            <p className="smalltextbold">{subscription?.stripeInvoices.upcoming?.subtotal! / 100} kr</p>
          </div>
          {subscription?.stripeInvoices.upcoming?.amount_due !== subscription?.stripeInvoices.upcoming?.subtotal && (
            <div className="flex justify-between space-x-10 py-3">
              <p className="smalltext">Anvendt saldo</p>
              <p className="smalltextbold">
                {(subscription?.stripeInvoices.upcoming?.amount_due! -
                  subscription?.stripeInvoices.upcoming?.subtotal!) /
                  100}{' '}
                kr
              </p>
            </div>
          )}
          <div className="h-1 w-full rounded-full bg-v2-purple-200" />
          <div className="flex justify-between space-x-10 py-3">
            <p className="smalltext">Neste beregnede betaling</p>
            <p className="smalltextbold">{subscription?.stripeInvoices.upcoming?.amount_due! / 100} kr</p>
          </div>
        </div>
      </div>
      <h2 className="normalheading">Hvordan regner vi ut neste faktura?</h2>
      <p className="-mt-4">
        Endringer i et abonnement som oppgradering eller nye brukere kan resultere i forholdsmessige kostnader. For
        eksempel, hvis det registreres en ny bruker halvveis i måneden vil de resterende 15 dagene før neste periode
        faktureres.
      </p>
    </>
  );
}

function translateLineDescription(lineDescription: string) {
  let stringBuilder;
  stringBuilder = lineDescription.replaceAll('Remaining time on', 'Bruksbelastning for');
  stringBuilder = stringBuilder.replaceAll('Unused time on', 'Brukskreditt fra');
  stringBuilder = stringBuilder.replaceAll('after', 'etter');
  stringBuilder = stringBuilder.replaceAll('at ', '');
  stringBuilder = stringBuilder.replaceAll('month', 'mnd');

  return stringBuilder;
}
